import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../../General/Loading";
import Error from "../../General/Error";
import site from "../../../_data/marketTheme.json";

import { getApplicationsToOpportunities } from "../../API/API";
import YourApplicantsRow from "./YourApplicantsRow";

function resultInFilter(needle, haystack) {
  var results = true;
  if (needle) {
    var needles = needle.split(" ");
    console.log(needles);
    needles &&
      needles.forEach((n) => {
        if (
          results &&
          JSON.stringify(haystack).toLowerCase().includes(n.toLowerCase())
        ) {
          results = true;
        } else {
          results = false;
        }
      });
  }
  return results;

  // console.log(results);
  // console.log(needle, JSON.stringify(haystack));
}

function YourApplicants({
  user,
  companyId,
  accessToken,
  meta,
  limitAmount,
  appId,
}) {
  const [applications, setApplications] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("");
  const [isError, setIsError] = useState(false);

  const limit = limitAmount ? `&limitAmount=${limitAmount}` : "";

  useEffect(() => {
    if (!applications && companyId?.length) {
      getApplicationsToOpportunities(companyId, user?.sub, accessToken, limit)
        .then((data) => {
          setApplications(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [applications, meta.company, appId, accessToken, companyId, user, limit]);

  return (
    <>
      <div className="">
        {isLoading ? (
          <Loading bg={"bg-" + site.tablePageBG} />
        ) : (
          <>
            {!meta.company ? (
              <Loading bg={"bg-" + site.tablePageBG} />
            ) : (
              <>
                {!isError ? (
                  <div
                    className={`container-fluid bg-${site.tablePageBG} py-5 min-vh-100`}
                  >
                    <div className="row contained-xl">
                      <div className={`applicationBlock offset-lg-1 col-lg-10`}>
                        <div className="row justify-content-between contained-xl">
                          <div className="col-lg-6">
                            <h1 className="mb-4">{site.yourApplicantsName}</h1>
                          </div>
                          {applications?.records?.length > 5 && (
                            <div className="col-lg-4 text-right align-self-center">
                              <div className="form-group">
                                <input
                                  className="form-control mb-0"
                                  placeholder="Filter Your Applications"
                                  onChange={(e) => setFilter(e.target.value)}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="d-none d-lg-block small col-12 p-0">
                          <div className="bg-primary mb-2 p-4 px-lg-3 py-lg-2">
                            <div className="row text-uppercase font-weight-bold">
                              <div className="col-6 col-lg-3  align-self-center font-weight-bold">
                                Opportunity
                              </div>
                              <div className="col-6 col-lg-3  align-self-center font-weight-bold">
                                Company
                              </div>

                              <div className="col-6 col-lg-2  align-self-center font-weight-bold">
                                Due Diligence
                              </div>
                              <div className="col-12 col-lg-2  text-center align-self-center font-weight-bold">
                                Status
                              </div>
                              <div className="col-6 col-lg-12  align-self-center font-weight-bold"></div>
                            </div>
                          </div>
                        </div>
                        <div className="row small">
                          {applications?.records?.length > 0 ? (
                            applications?.records.map((application) => (
                              <React.Fragment key={application.id}>
                                {resultInFilter(filter, application) && (
                                  <YourApplicantsRow
                                    {...application.fields}
                                    viewing={appId}
                                    user={user}
                                    accessToken={accessToken}
                                    recId={application.id}
                                  />
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <div className="col-12 mt-3 px-4">
                              <p>No Applicants found</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-10 offset-lg-1 mt-4 text-right">
                        <Link
                          to="/marketplace/listings/"
                          className="btn btn-secondary btn-sm"
                        >
                          View Your Adverts
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Error />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default YourApplicants;
