import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import site from "../_data/marketTheme.json";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

import { getOpportunity, applyOpportunity } from "../Components/API/API";
import OpportunityApplyButton from "../Components/Marketplace/ApplyButton";
import Loading from "../Components/General/Loading";
import Error from "../Components/General/Error";
import Icon from "../Components/General/Icon";
import Moment from "react-moment";

function OpportunityApply({
  user,
  meta,
  accessToken,
  isAuthenticated,
  companyId,
}) {
  const [opportunity, setOpportunity] = useState(false);
  const [note, setNote] = useState(null);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isError, setIsError] = useState(false);

  const [applied, setApplied] = useState(false);
  const params = useParams();
  const opportunityId = params.opportunity;

  useEffect(() => {
    if (!opportunity && opportunityId && companyId?.length) {
      if (!opportunity && opportunityId && accessToken) {
        getOpportunity(opportunityId, user?.sub, accessToken, companyId)
          .then((res) => {
            setOpportunity(res);
            if (res?.fields?.applyingCompanies) {
              setApplied(true);
            }
            setIsLoadingData(false);
          })
          .catch((error) => {
            setIsError(true);
          });
      }
    }
  }, [opportunity, opportunityId, meta, user, accessToken, companyId]);

  function submitApplication() {
    applyOpportunity(opportunityId, note, user.sub, accessToken, meta.company)
      .then((res) => {
        setApplied(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Helmet>
        {opportunity ? (
          <title>
            {site.OpportunitiesApply} / {opportunity.fields.Name} /{" "}
            {site.OpportunitiesName} / {site.BrandName}{" "}
          </title>
        ) : (
          <title>
            {site.OpportunitiesName} / {site.BrandName}
          </title>
        )}
      </Helmet>

      {isLoadingData && <Loading />}
      {isError && <Error />}
      {opportunity && (
        <>
          <BreadCrumb
            crumbs={[
              { title: "Browsing:", url: "" },
              {
                title: site.OpportunitiesName,
                url: "/marketplace/opportunities/",
              },
              {
                title: opportunity.fields.Name,
                url: "/marketplace/opportunities/" + opportunity?.id,
              },
              { title: "Apply", url: "" },
            ]}
          />
        </>
      )}

      <section className="bg-light pb-5">
        {opportunity && (
          <div className="position-relative container-fluid pt-3">
            <div className="row contained-xl justify-content-center">
              {!applied && (
                <>
                  <div className="col-12 col-lg-8 mt-lg-5">
                    <h1 className="h3 font-family-display">
                      <span className="h4 d-block text-secondary">
                        You are about to apply to:
                      </span>{" "}
                      {opportunity.fields.Name}
                    </h1>
                    <h2 className="h6 mb-4">
                      <span className="">{site.OpportunitiesAdvertisedBy}</span>{" "}
                      {opportunity.fields.companyName}
                    </h2>
                    <p className="small">
                      ⚠️ Please read through the campaign requirements and terms
                      and conditions. <br />
                      When you are ready add your notes and apply.
                    </p>
                  </div>
                </>
              )}
              <div className={`col-12 col-lg-8 ${applied ? "pt-lg-5" : ""}`}>
                {!applied && (
                  <>
                    <div className="row ">
                      <div className={`col-lg-6`}>
                        <h4 className="mt-lg-4 text-secondary">
                          Campaign Description
                        </h4>
                        <p className="small">{opportunity.fields.ShortDesc}</p>
                        {opportunity.fields.opportunityContentApproval && (
                          <>
                            <h4 className="mt-4 text-secondary">
                              Content Approval Required
                            </h4>
                            <div className="text-left hidden mb-4 small">
                              This opportunity requires that{" "}
                              {opportunity.fields.companyName} approve any
                              content, such as, but not limited to: adverts,
                              content, emails and landing pages.
                            </div>
                          </>
                        )}
                        <h4 className="mt-4 text-secondary">Terms</h4>
                        <div
                          className="text-left hidden mb-4 small"
                          dangerouslySetInnerHTML={{
                            __html: opportunity.fields.terms.replace(
                              /(?:\r\n|\r|\n)/g,
                              "<br>"
                            ),
                          }}
                        />
                      </div>
                      <div className={`col-lg-6 mt-4 small`}>
                        <h2 className="h4 text-secondary">Details</h2>
                        <ul className="list-unstyled">
                          <li className="pb-2 mb-1 border-bottom pt-1">
                            <span className="font-weight-bold">
                              Campaign Dates
                            </span>{" "}
                            <div>
                              <Icon
                                icon="calendar"
                                className="text-secondary mr-1"
                              />{" "}
                              <Moment format="DD/MM/YYYY">
                                {opportunity.fields.StartDate}
                              </Moment>
                              {" - "}
                              <Moment format="DD/MM/YYYY">
                                {opportunity.fields.EndDate}
                              </Moment>
                            </div>
                          </li>
                          <li className="pb-2 mb-1 border-bottom pt-1">
                            <span className="font-weight-bold">Sector</span>{" "}
                            <div>
                              <Icon
                                icon="folders"
                                className="text-secondary mr-1"
                              />{" "}
                              {opportunity.fields.sector}
                            </div>
                          </li>
                          <li className="pb-2 mb-1 border-bottom pt-1">
                            <span className="font-weight-bold">Audience</span>{" "}
                            <div>
                              {opportunity.fields.Audience && (
                                <span className="ml-2">
                                  <Icon
                                    icon="user"
                                    className="text-secondary mr-1"
                                  />{" "}
                                  {opportunity.fields.Audience}
                                </span>
                              )}
                            </div>
                          </li>
                          <li className="pb-2 mb-1 border-bottom pt-1">
                            <span className="font-weight-bold">Country</span>{" "}
                            <div>
                              {opportunity.fields.country && (
                                <span className="ml-2">
                                  <Icon
                                    icon="earth"
                                    className="text-secondary mr-1"
                                  />{" "}
                                  {opportunity.fields.country}
                                </span>
                              )}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-lg-12">
                        <div className="text-left hidden my-4 small bg-light">
                          <h4>Add a Note</h4>
                          <p>
                            You can send an addition note to give examples,
                            links, testimonials or more.
                            <br />
                            Please Note: We send all your profile information to
                            the advertiser, along with any due diligence doc.
                          </p>
                          <textarea
                            className="form-control bg-white border-primary px-3"
                            placeholder="Time to sell yourself... tell us about your company and how you'd manage this campaign"
                            rows="10"
                            onChange={(e) => {
                              setNote(e.target.value);
                            }}
                          >
                            {note}
                          </textarea>
                        </div>
                      </div>
                      <div className="col-lg-12 py-lg-4">
                        <p className="small">
                          By submitting an application you are agreeing to the
                          outlined terms and conditions and can confirm your
                          suitability to deliver to this campaign
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <OpportunityApplyButton
                  opportunity={opportunity}
                  meta={meta}
                  isAuthenticated={isAuthenticated}
                  apply={true}
                  submitApplication={submitApplication}
                />
                {applied && (
                  <div className="mt-5">
                    <h2>What Next?</h2>
                    <p>
                      Your application will be process and you'll hear a
                      response soon.
                    </p>
                    <Link
                      to="/marketplace/opportunities/"
                      className="btn btn-success mr-2"
                    >
                      Find More Adverts
                    </Link>
                    <Link
                      to="/marketplace/applications/"
                      className="btn btn-outline-success"
                    >
                      View your Applications
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
export default OpportunityApply;
