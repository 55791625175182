import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { Helmet } from "react-helmet-async";

import Loading from "./../Components/General/Loading";
import Error from "../Components/General/Error";

import Input from "../Components/Forms/Input";
import YesNo from "../Components/Forms/YesNo";

import site from "./../_data/marketTheme.json";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

import { applyDD } from "../Components/API/API";
import { Link } from "react-router-dom";
import Confetti from "../Components/General/Confetti";

function DueDiligenceForm({ user, meta, accessToken, companyId }) {
  const [applied, setApplied] = useState(false);
  const [error, setError] = useState("");
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" });

  const watchDueDiligenceModernSlaveryPolicy = watch(
    "dueDiligenceModernSlaveryPolicy",
    false
  );
  const watchDueDiligenceFCAInvestigations = watch(
    "dueDiligenceFCAInvestigations",
    false
  );

  let handCreation = async (data) => {
    let submitUser = user?.sub ? user.sub : "";
    let SubmitCompany = meta?.company ? meta.company : "";

    applyDD(data, submitUser, SubmitCompany, accessToken)
      .then((res) => {
        setApplied(true);
        reset();
      })
      .catch((error) => {
        setError("Error");
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {site.nav.dueDiligence} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: `${site.dueDiligenceName}`,
            url: "./",
          },
          {
            title: `Apply`,
            url: "./",
          },
        ]}
      />

      <div className="">
        {false ? ( //isLoading
          <Loading bg="dark-white" />
        ) : (
          <>
            {!error ? (
              <>
                {applied ? (
                  <div className="container-fluid  pt-5">
                    <div className="row contained-xl justify-content-center py-4">
                      <div className="col-12 col-lg-10">
                        <h1 className="font-family-displayh2">
                          {site.dueDiligenceApply}
                        </h1>
                          <Confetti />
                        <h3 className="mt-5">
                          Thank you for submitting your application.
                          <br />
                          We'll be in touch soon.
                        </h3>
                        <div className="mt-4">
                          <Link to="/due-diligence/" className="btn btn-secondary">Go Back</Link>
                        </div>
                        {!user?.sub ||
                          (!meta?.company && (
                            <div className="">
                              <Link
                                to="/due-diligence/"
                                className="btn btn-primary"
                              >
                                Back
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={handleSubmit(handCreation)}
                    className="bg-light pb-5"
                  >
                    <div className="container-fluid  pt-5">
                      <div className="row contained-xl justify-content-center py-4">
                        <div className="col-12 col-lg-10">
                          <h1 className="font-family-displayh2">
                            {site.dueDiligenceApply}
                          </h1>
                          {/* <p>
                            Aim of due dil - Vet users on PH advertisers behalf
                            so that we can confirm the lead generator generates
                            leads with the correct consent statements, terms and
                            privacy policy. We’ll also remove opaqueness around
                            how lead generators generate leads.
                          </p> */}

                          <h3 className="font-family-display h4 mt-5 mb-3">
                            Your Company Details
                          </h3>
                          <fieldset className={`mt-4 bg-white mb-5 p-4`}>
                            <div className="row">
                              <div className="col-lg-12">
                                <Input
                                  name="dueDiligenceCompany"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={errors?.dueDiligenceCompany?.message}
                                  register={register}
                                  dark={false}
                                  label="Name of Company: "
                                  type="text"
                                />
                              </div>

                              {/* TODO:  */}

                              <div className="col-lg-12">
                                <Input
                                  name="dueDiligenceAddress"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={errors?.dueDiligenceAddress?.message}
                                  register={register}
                                  dark={false}
                                  label="Company Registered Address:"
                                  type="textarea"
                                />
                              </div>
                              <div className="col-lg-12">
                                <Input
                                  name="dueDiligenceCompanyURL"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceCompanyURL?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Company Website Address:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceCompanyNum"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceCompanyNum?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Company Registration Number:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceDateofIncorporation"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceDateofIncorporation
                                      ?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Date of Incorporation:"
                                  type="date"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceNumEmployees"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceNumEmployees?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Number of employees:"
                                  type="number"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceTradingNames"
                                  placeholder="..."
                                  error={
                                    errors?.dueDiligenceTradingNames?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="All Active Trading Names:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-12">
                                <Input
                                  name="dueDiligenceTradingAddress"
                                  placeholder="..."
                                  error={
                                    errors?.dueDiligenceTradingAddress?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Company Trading Address (if different from registered address):"
                                  type="textarea"
                                />
                              </div>
                            </div>
                          </fieldset>

                          <h3 className="font-family-display h4 mb-3">
                            Compliance/DPO
                          </h3>
                          <fieldset className={`mt-4 bg-white mb-5 p-4`}>
                            <div className="row">
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceComplianceContact"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceComplianceContact
                                      ?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Compliance/DPO Contact Name:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceCompliancePhone"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceCompliancePhone?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Compliance/DPO Phone:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceComplianceEmail"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceComplianceEmail?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Compliance/DPO Email:"
                                  type="text"
                                />
                              </div>
                            </div>
                          </fieldset>

                          <h3 className="font-family-display h4 mb-3">
                            Your Web Properties
                          </h3>
                          <fieldset className={`mt-4 bg-white mb-5 p-4`}>
                            <div className="row">
                              <div className="col-lg-12">
                                <Input
                                  name="dueDiligenceURLs"
                                  placeholder="..."
                                  error={errors?.dueDiligenceURLs?.message}
                                  register={register}
                                  dark={false}
                                  label="Please Detail All Active Web Domains:"
                                  type="textarea"
                                />
                              </div>
                              <div className="col-lg-12">
                                <YesNo
                                  name="dueDiligenceLPBuild"
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={errors?.dueDiligenceLPBuild?.message}
                                  register={register}
                                  dark={false}
                                  label="Would you build a landing page using your clients brand assets and copy?"
                                  type="Radio"
                                />
                              </div>
                            </div>
                          </fieldset>

                          <h3 className="font-family-display h4 mb-3">
                            Regulatory Details
                          </h3>
                          <fieldset className={`mt-4 bg-white mb-5 p-4`}>
                            <div className="row">
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceDPRNum"
                                  placeholder="..."
                                  error={errors?.dueDiligenceDPRNum?.message}
                                  register={register}
                                  dark={false}
                                  label="ICO / Data Protection Registration Number:"
                                  type="text"
                                />
                              </div>

                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceFCANum"
                                  placeholder="..."
                                  error={errors?.dueDiligenceFCANum?.message}
                                  register={register}
                                  dark={false}
                                  label="FCA Firm Reference Number (where applicable):"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-12">
                                <Input
                                  name="dueDiligenceFCAPermissions"
                                  placeholder="..."
                                  error={
                                    errors?.dueDiligenceFCAPermissions?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="FCA Permissions:"
                                  type="textarea"
                                />
                              </div>
                            </div>
                          </fieldset>

                          <h3 className="font-family-display h4 mb-3">
                            Policies Held
                          </h3>
                          <fieldset className={`mb-4`}>
                            <div className="row justify-content-between">
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceLPTerms"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceLPTerms?.message}
                                    register={register}
                                    dark={false}
                                    label="Do you display the compliant and transparent privacy policies and terms to all users that sign up to your landing pages?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceAds"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceAds?.message}
                                    register={register}
                                    dark={false}
                                    label="Will you share ads and any other assets/content used to drive traffic to your pages with your clients?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceCalls"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceCalls?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation record customer calls?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceThirdParty"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceThirdParty?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Can you supply all the names (if name) of third party companies that you buy leads from?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceReference"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceReference?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Would you be happy for any new client to contact your current clients for a reference?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceDPP"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceDPP?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed Data Protection Policy? "
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceAFCP"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceAFCP?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed Anti-Financial Crime Policy?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceAMLP"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceAMLP?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed Anti-Money Laundering Policy?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceTCS"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceTCS?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed Training and Competency Scheme?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceFTAGCOP"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceFTAGCOP?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed fair treatment and good customer outcomes Policy?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceVCP"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceVCP?.message}
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed vulnerable customer policy?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceComplains"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceComplains?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have an up to date and regularly reviewed complaints handling policy and procedure that meets the requirements of DISP?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceEmployeeFitness"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceEmployeeFitness
                                        ?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Does your organisation assess the fitness & propriety of appropriate employees?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceFCAInvestigations"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceFCAInvestigations
                                        ?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Are you currently the subject of any FCA investigation / enquiry / review?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              {/* TODO: FCA investigation details */}

                              {watchDueDiligenceFCAInvestigations === "Y" && (
                                <div className="col-lg-12 mb-4">
                                  <div className="h-100 p-4 bg-white">
                                    <Input
                                      name="dueDiligenceFCAInvestigationsDetails"
                                      placeholder="..."
                                      error={
                                        errors
                                          ?.dueDiligenceFCAInvestigationsDetails
                                          ?.message
                                      }
                                      register={register}
                                      dark={false}
                                      label="Please provide details of the FCA investigation / enquiry / review:"
                                      type="textarea"
                                    />
                                  </div>
                                </div>
                              )}


                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceFCAEnforcement"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceFCAEnforcement
                                        ?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Have you ever been the subject of any FCA (or previously FSA) enforcement action? (if yes please provide full details)"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              {/* TODO: FCA enforcement details */}
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceBankruptcy"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceBankruptcy?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Has your company ever been presented with a petition for bankruptcy?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceCriminal"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceCriminal?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Has the applicant organisation ever been subject to criminal investigation or proceedings?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceDebts"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceDebts?.message}
                                    register={register}
                                    dark={false}
                                    label="Does the applicant organisation have any unsatisfied judgments, debts or outstanding awards against it?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceBCP"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceBCP?.message}
                                    register={register}
                                    dark={false}
                                    label="Do you have a Business Continuity Plan? (BCP)"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceFCAReg"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceFCAReg?.message}
                                    register={register}
                                    dark={false}
                                    label="Are all your Approved Persons and appropriate FCA Permissions displaying correctly on the FCA Register? "
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceSMCert"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceSMCert?.message}
                                    register={register}
                                    dark={false}
                                    label="Please confirm you are now structured in accordance with the Senior Managers & Certification Regime?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceDataHandling"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceDataHandling?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Please confirm you have a data handling and information security policy in place, and whether you are aligned and/or certified to ISO27001 standards"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceGDPR"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={errors?.dueDiligenceGDPR?.message}
                                    register={register}
                                    dark={false}
                                    label="In the last 12 months, has your company and assets such as landing pages and websites been screened for GDPR compliance?"
                                    type="Radio"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceModernSlaveryPolicy"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceModernSlaveryPolicy
                                        ?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Does your organisation have a policy or policies in place to deal with modern slavery?"
                                    type="Radio"
                                  />
                                </div>
                              </div>

                              {watchDueDiligenceModernSlaveryPolicy === "Y" && (
                                <div className="col-lg-12 mb-4">
                                  <div className="h-100 p-4 bg-white">
                                    <Input
                                      name="dueDiligenceModernSlaveryPolicyUrl"
                                      placeholder="..."
                                      error={
                                        errors
                                          ?.dueDiligenceModernSlaveryPolicyUrl
                                          ?.message
                                      }
                                      register={register}
                                      dark={false}
                                      label="Please provide a link to your modern slavery policy"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceModernSlaveryTraining"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceModernSlaveryTraining
                                        ?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Are staff in your organisation trained on how to identify, assess and respond to modern slavery risks?"
                                    type="Radio"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceModernSlaveryDueDil"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceModernSlaveryDueDil
                                        ?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Does your organisation engage in any due diligence activities to identify, prevent and mitigate risks specific to modern slavery in its operations and supply chains?"
                                    type="Radio"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6 mb-4">
                                <div className="h-100 p-4 bg-white">
                                  <YesNo
                                    name="dueDiligenceDoubleOptIn"
                                    validation={{
                                      required: "This is required.",
                                    }}
                                    error={
                                      errors?.dueDiligenceDoubleOptIn?.message
                                    }
                                    register={register}
                                    dark={false}
                                    label="Are you able to work with and demonstrate a Double Opt in for your leads?"
                                    type="Radio"
                                  />
                                </div>
                              </div>
                            </div>
                          </fieldset>

                          <h3 className="font-family-display h4 mb-3">
                            Your Contact Details
                          </h3>
                          <p>Details of the person submitting the form:</p>
                          <fieldset className={`mt-4 bg-white mb-5 p-4`}>
                            <div className="row">
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceContactName"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceContactName?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Your Name"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceContactPosition"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceContactPosition?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Position in Company:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceContactEmail"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceContactEmail?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Contact Email:"
                                  type="text"
                                />
                              </div>
                              <div className="col-lg-6">
                                <Input
                                  name="dueDiligenceContactPhone"
                                  placeholder="..."
                                  validation={{
                                    required: "This is required.",
                                  }}
                                  error={
                                    errors?.dueDiligenceContactPhone?.message
                                  }
                                  register={register}
                                  dark={false}
                                  label="Contact phone number:"
                                  type="text"
                                />
                              </div>
                            </div>
                          </fieldset>
                          <button
                            type="submit"
                            className="btn btn-block py-3 btn-secondary mt-2"
                          >
                            Submit Your Due Diligence Application
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </>
            ) : (
              <Error />
            )}
          </>
        )}
      </div>
    </>
  );
}
export default DueDiligenceForm;
