import React from "react";
import { Helmet } from "react-helmet-async";
import site from "./../_data/marketTheme.json";
import BreadCrumb from "../Components/Navigation/BreadCrumb";
import YourListings from "../Components/Marketplace/Listings/YourListings";

function YourListingsPage({ user, companyId, company, meta, accessToken }) {
  return (
    <>
      <Helmet>
        <title>
          {site.yourListingsName} / {site.OpportunitiesName} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.yourListingsName,
            url: "./",
          },
        ]}
      />
      <YourListings
        user={user}
        company={company}
        companyId={companyId}
        meta={meta}
        accessToken={accessToken}
      />
    </>
  );
}
export default YourListingsPage;
