import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import site from "../_data/marketTheme.json";

import ContentApproval from "../Components/ContentApproval/ContentApproval";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

function ContentApprovalPage({
  user,
  companyId,
  meta,
  accessToken,
  company,
}) {
  const params = useParams();
  const appId = params.appId;
  return (
    <>
      <Helmet>
        <title>
          Your Content Approvals / {site.account} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: "Content Approval",
            url: "./",
          },
        ]}
      />
      <ContentApproval
        user={user}
        company={company}
        companyId={companyId}
        meta={meta}
        accessToken={accessToken}
        appId={appId}
      />
    </>
  );
}
export default ContentApprovalPage;
