import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import site from "./../../_data/marketTheme.json";

function Terms({ user, meta, isAuthenticated, isLoading }) {
  const { getAccessTokenSilently } = useAuth0();

  const [userUpdated, setUserUpdated] = useState(false);
  const [generateLeadTermsAccepted, setGenerateLeadTermsAccepted] = useState(
    meta.generateLeadTermsAccepted
  );

  const [postOpportunitiesTermsAccepted, setPostOpportunitiesTermsAccepted] =
    useState(meta.postOpportunitiesTermsAccepted);

  useEffect(() => {
    if (!isLoading && meta) {
      setGenerateLeadTermsAccepted(meta.generateLeadTermsAccepted);
      setPostOpportunitiesTermsAccepted(meta.postOpportunitiesTermsAccepted);
    }
    if (
      !userUpdated &&
      !isLoading &&
      (generateLeadTermsAccepted || postOpportunitiesTermsAccepted)
    ) {
      setUser();
      setUserUpdated(true);
    }

    // TODO: protect this function
    async function setUser() {
      if (user?.sub && meta?.company) {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        });

        try {
          const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;
          await fetch(userDetailsByIdUrl, {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "content-type": "application/json",
            },
            body: JSON.stringify({
              user_metadata: {
                company: meta.company,
                generateLeadTermsAccepted: generateLeadTermsAccepted,
                postOpportunitiesTermsAccepted: postOpportunitiesTermsAccepted,
              },
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              window.location.reload(false);
            })
            .catch((error) => {
              console.log("error", error);
            });
        } catch (e) {
          console.log(e.message);
        }
      }
    }
  }, [
    meta,
    user,
    getAccessTokenSilently,
    userUpdated,
    isLoading,
    generateLeadTermsAccepted,
    postOpportunitiesTermsAccepted,
  ]);

  return (
    <>
      <Helmet>
        <title>Account Terms / {site.BrandName}</title>
      </Helmet>

      {meta && (generateLeadTermsAccepted || postOpportunitiesTermsAccepted) ? (
        <Navigate
          to={{
            pathname: "/account/",
          }}
        />
      ) : (
        <>
          {!isLoading && (
            <main className="loading bg-primary py-lg-5">
              <div className="container-fluid pt-4 pb-lg-5">
                <div className="row contained-xl">
                  <div className="col-11 col-lg-6 offset-lg-1 mb-3">
                    <h1 className="font-family-display">
                      Where do you fit?
                    </h1>
                    <p className="lead">
                      We design our accounts around what you need and what you
                      do.
                    </p>
                  </div>
                </div>
                {isAuthenticated && meta && (
                  <div className="row contained-xl justify-content-center pb-5 mt-lg-3">
                    <div className="col-12 col-lg-5 align-content-center mb-4">
                      <div className=" p-4 p-lg-5 bg-secondary-grad-dark h-100">
                        <div className="">
                          <h2 className="h3 font-family-display mb-2">
                            We Buy Leads
                          </h2>
                          <p className="">
                            Looking to connect with trusted lead sellers and buy leads.
                          </p>
                          <div className="mt-4">
                            <button
                              className="btn btn-lg btn-outline-white stretched-link"
                              onClick={() => {
                                setPostOpportunitiesTermsAccepted(true);
                              }}
                            >
                              Choose
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-lg-5  align-content-center mb-4">
                      <div className=" p-4 p-lg-5 bg-success-grad-dark h-100">
                        <div className="">
                          <h2 className="h3 font-family-display mb-2">
                            We Sell Leads
                          </h2>
                          <p className="">
                            Wanting to connect with new brands and deliver fresh leads.
                          </p>
                          <div className="mt-4">
                            <button
                              className="btn btn-lg btn-outline-white stretched-link"
                              onClick={() => {
                                setGenerateLeadTermsAccepted(true);
                              }}
                            >
                              Choose
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-10 mt-4">
                      <p className="small">
                        Please select the appropriate account type below and
                        confirm you have read and understood our<br/> <a href="https://www.partnerhype.com/privacy" target="_blank" rel="noreferrer" className="text-secondary">privacy policy</a> and <a href="https://www.partnerhype.com/terms" target="_blank" rel="noreferrer" className="text-secondary">terms and
                        conditions</a>.
                        {/* and <a href="/pricing/" target="_blank" className="text-secondary">pricing policy</a> 
                        for Lead Buyers. */}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </main>
          )}
        </>
      )}
    </>
  );
}
export default Terms;
