import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../General/Loading";
import Error from "../General/Error";
import site from "../../_data/marketTheme.json";
import TableData from "../Content/TableData";

import { getYourOpportunities } from "../API/API";

function YourAdvertsHome({ user, companyId, meta, limitAmount, accessToken }) {
  const [certificates, setCertificates] = useState(false);
  const [viewInfo, setViewInfo] = useState({ id: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const appId = params.appId;

  const limit = limitAmount ? `&limit=${limitAmount}` : "";

  useEffect(() => {
    if (!certificates && companyId?.length) {
      getYourOpportunities(companyId, user?.sub, accessToken, limit)
        .then((data) => {
          setCertificates(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [certificates, meta.company, appId, accessToken, companyId, user, limit]);

  return (
    <>
      {isLoading ? (
        <Loading mini={true} bg={"bg-" + site.tablePageBG} />
      ) : (
        <>
          {!meta.company ? (
            <Loading bg={"bg-" + site.tablePageBG} />
          ) : (
            <>
              {!isError ? (
                <div className={`container-fluid`}>
                  <div className={`applicationBlock`}>
                    {certificates?.records?.length > 0 ? (
                      <TableData
                        viewInfo={viewInfo}
                        setViewInfo={setViewInfo}
                        data={certificates.records}
                        bgAlt="light"
                        accent="primary"
                        hideHeaders={true}
                        headers={[
                          {
                            title: "Opportunity",
                            mobileSize: 6,
                            desktopSize: 3,
                          },
                          {
                            title: "Desc",
                            mobileSize: 6,
                            desktopSize: 3,
                          },
                          {
                            title: "Sector",
                            mobileSize: 6,
                            desktopSize: 3,
                          },
                          {
                            title: "Status",
                            mobileSize: 12,
                            desktopSize: 2,
                            textAlign: "center",
                          },
                          {
                            title: "View",
                            mobileSize: 6,
                            desktopSize: 1,
                          },
                        ]}
                        content={[
                          {
                            field: "Name",
                            mobileSize: 6,
                            desktopSize: 3,
                            className: "font-weight-bold",
                          },
                          {
                            field: "sector",
                            mobileSize: 6,
                            desktopSize: 2,
                          },
                          {
                            field: "ShortDesc",
                            mobileSize: 6,
                            desktopSize: 3,
                            className: "small",
                          },
                          {
                            field: "status",
                            mobileSize: 6,
                            desktopSize: 3,
                            type: "status",
                            textAlign: "center",
                          },
                          {
                            link: "/marketplace/listings/",
                            final: true,
                          },
                        ]}
                      />
                    ) : (
                      <div className="">
                        <p>Not posted an opportunity yet?</p>
                        <Link
                          to="/marketplace/opportunities/create/"
                          className="btn btn-sm btn-primary"
                        >
                          Post One Now!
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Error />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
export default YourAdvertsHome;
