import React from "react";
import { Helmet } from "react-helmet-async";
import site from "./../../_data/marketTheme.json";
import Contact from "../../Components/Content/Contact";
import LoginSignupButtons from "../../Components/Authentication/LoginSignupButtons";
import Rescue from "../../Components/Content/Rescue";
// import Testimonials from "../../Components/Content/Testimonials";
// import HowDoesItWork from "../../Components/Content/HowDoesItWork";
// import About from "../../Components/Content/About";
// import Pricing from "../../Components/Content/Pricing";
// import Logos from "../../Components/Content/Logos";
// import HomeHero from "../../Components/Home/HomeHero";

import HeroImage from "../../img/invite-card-web.png";

function WelcomeInvite({ user, isAuthenticated }) {
  return (
    <>
      <Helmet>
        <title>Meet Your New Lead Partner / {site.BrandName}</title>
      </Helmet>
      <main className={`bg-light`}>
        <div className="container-fluid">
          <div className="row contained-xl justify-content-center">
            <div className="order-lg-2 col-10 col-md-8 col-lg-5 align-self-center py-lg-5">
              <img
                src={HeroImage}
                alt=""
                className="img-fluid opacity-100 w-100"
              />
            </div>
            <div className="order-lg-1 col-11 col-lg-5 align-self-center my-xl-5 pt-lg-4 pb-lg-5 text-center text-lg-left">
              <h1 className="display-3 mb-4">
                Meet Your New Lead Partner
                <span className="text-secondary">.</span>{" "}
              </h1>
              <p className="lead font-weight-bold">
                You've been specially selected to connect with Lead Buyers or Trusted Lead
                Sellers on our open lead marketplace.
              </p>
              <p>
                Are you ready for us to help you build partnerships, drive trust and{" "}
                <u>smash results!</u>
              </p>
              <LoginSignupButtons
                showLogin={false}
                realSignup={true}
                className={`mt-2`}
                size={`lg`}
                textSignUp={`Join Early Now!`}
              />
            </div>
          </div>
        </div>
      </main>
      {/* <section>
        <div className="container-fluid py-5">
          <div className="row contained-xl justify-content-center">
            <div className="col-lg-4">
              <img
                src="https://images.unsplash.com/photo-1580894732930-0babd100d356?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&w=1000&h=1000&q=69&fp-x=0.82&fp-y=0.4&fp-z=1"
                alt=""
                className="img-fluid float-right align-items-end rounded shadow"
              />
            </div>
            <div className="col-lg-5 offset-lg-1 py-lg-5">
              <h2 className="h3 mb-lg-5 font-family-display">
                How Does it Work?
              </h2>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">📫</span>Buyers List
                Opportunities
              </div>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">🔐</span>Sellers
                Complete Due-Diligence
              </div>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">👋</span>Sellers Apply
                to Opportunities
              </div>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">🤝</span>Buyers Approve
                Sellers
              </div>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">🚀</span>Sellers Submit
                Leads
              </div>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">🎁</span>Good Leads Get
                Delivered
              </div>
              <div className="h4 mb-3">
                <span className="mr-3 h3 mb-0 d-inline">🎉</span>Everyone is
                Transparent
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <Logos /> */}
      {/* <Testimonials /> */}
      {/* <HowDoesItWork /> */}
      {/* <About /> */}
      {/* <Pricing /> */}
      <Rescue />
      <Contact />
    </>
  );
}
export default WelcomeInvite;
