import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

export default function App({ data, ChartTitle }) {
  ChartJS.register(
    CategoryScale,
    BarElement,
  );

  const options = {
    plugins: {
      legend: false,
      title: false,
    },
    indexAxis: "y",
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const labels = [""];

  const theme = {
    quarantine: "#3c4c9b",
    rejected: "#f43e70",
    pending: "#ffba08",
    accepted: "#2ec4b6",
  };

  const dataLabels = Object.keys(theme);
  // const states = Object.keys(data[labels[0]]);

  const dataSet = [];

  dataLabels.map((state, i) =>
    dataSet.push({
      label: state,
      data: dataLabels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: theme[state],
      backgroundColor: theme[state],
    })
  );
  const dataSetReturn = {
    labels,
    datasets: dataSet,
  };

  return <Bar options={options} data={dataSetReturn} height={50} />;
}
