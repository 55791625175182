import { React, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon from "./Icon";

function CopyButton({
  copy,
  message = <Icon type="far" icon="copy" className="opacity-60" />,
  success = <Icon icon="circle-check" className="text-success" />,
  className = "bg-transparent border-0 p-0 ml-1",
  timeout = 1500,
}) {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={copy}
      onCopy={function () {
        setCopied(true);
        setTimeout(function () {
          setCopied(false);
        }, timeout);
      }}
    >
      <button className={className}>{copied ? success : message}</button>
    </CopyToClipboard>
  );
}
export default CopyButton;
