import React from "react";
import { Helmet } from "react-helmet-async";
import site from "./../_data/marketTheme.json";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <>
      <Helmet>
        <title>
          {site.NotFoundTitle} / {site.BrandName}
        </title>
      </Helmet>
      <main className="loading bg-primary vh-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-11 col-lg-8 align-content-center py-lg-5 text-center">
              <div className="p-4 my-5">
                <h1>{site.NotFoundTitle}</h1>
                <p className="lead">{site.NotFoundContent}</p>
                <div className="mt-5">
                  <Link to="/" className={`btn btn-secondary`}>
                    {site.NotFoundCTA}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default NotFound;
