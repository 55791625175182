import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import site from "./../../_data/marketTheme.json";

import { useForm } from "react-hook-form";

import BreadCrumb from "../../Components/Navigation/BreadCrumb";
import { Link } from "react-router-dom";
import CreateForm from "../../Components/Marketplace/Listings/CreateForm";
// import connectImage from "../../img/home.png";

function InvitedCreate({ user, isAuthenticated, company, companyId }) {
  const [message, setMessage] = useState(false);
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" });

  const watchOpportunityDateType = watch("opportunityDateType", false);

  useEffect(() => {
    if (companyId?.length) {
      reset({
        opportunityContactName: `${company.contactFirstName} ${company.contactSurname}`,
        opportunityContactCompany: company.companyName,
        opportunityContactEmail: company.companyEmail,
        opportunityContactPhone: company.companyPhone,
      });
    }
  }, [user, company, reset, companyId]);

  let handCreation = async (data) => {
    data.opportunityCompany = companyId;
    let res = await fetch(
      `${process.env.REACT_APP_API_URL}/createOpportunityInvited`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    await res.json();
    if (res.status === 200) {
      setMessage("Created");
    } else {
      setMessage("Some error occured");
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {site.createAdvertName} / {site.OpportunitiesName} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.createAdvertName,
            url: "./",
          },
        ]}
      />

      <main
        className={`${site.HomeBG} ${
          site.HomeBG !== site.NavBGDark && site.NavDark === true
            ? "py-5"
            : "pb-5"
        }`}
      >
        <div className="container-fluid">
          <div className="row contained-xl justify-content-center">
            {/* <div className="order-lg-2 col-10 col-md-8 col-lg-5 align-self-center py-lg-5">
              <img
                src={connectImage}
                alt=""
                className="img-fluid opacity-100 w-100"
              />
            </div> */}
            <div className="order-lg-1 col-11 col-lg-5 align-self-center mt-lg-5 pt-4 text-center">
              <h1 className="display-3 mb-4">
                Connect with Trusted Lead Sellers
                <span className="text-secondary">.</span>
              </h1>
              <p className="lead font-weight-normal mb-5 mb-lg-5">
                You've been invited to post your campaign advert, build
                partnerships, drive trust and <u>smash results!</u>
              </p>
              <a href="#start" className="btn btn-lg btn-secondary">
                Post Your Opportunity Now 👇
              </a>
              {/* <hr className="col-2 col-lg-1 border-thick border-secondary mx-auto mx-lg-0 my-4 px-0" /> */}
              {/* <LoginSignupButtons className="px-lg-5 py-lg-3 mx-2 mb-3 mb-lg-0 mx-lg-0 mr-lg-3" /> */}
            </div>
          </div>
        </div>
      </main>

      <main className={`loading bg-${site.createAdvertBG} py-5`} id="start">
        <div className="container-fluid mt-5 pt-5">
          <div className="row contained-xl justify-content-center">
            <div className="col-11 col-lg-8 align-content-center">
              <div className={``}>
                <h2 className="font-family-display">{site.createAdvertName}</h2>
                <p className="mb-5">Takes about 5 minutes.</p>
              </div>

              <form onSubmit={handleSubmit(handCreation)}>
                <CreateForm
                  register={register}
                  errors={errors}
                  watchOpportunityDateType={watchOpportunityDateType}
                />

                {message === "Error" ? (
                  <>
                    <div className="mt-5">
                      <h3>There was an Error?</h3>
                      <p>
                        Unfortunately there was an error in submitting your
                        Advert.
                      </p>
                      <p>
                        Please review your details above. If this continues to
                        fail, please contact us{" "}
                        <a href="mailto:support@partnerhype.com">
                          support@partnerhype.com
                        </a>
                      </p>
                      <button
                        type="submit"
                        className="btn btn-block py-3 btn-secondary mt-2"
                      >
                        Try Again
                      </button>
                    </div>
                  </>
                ) : message === "Created" ? (
                  <>
                    <button className="btn btn-success py-3 btn-secondary mt-2 w-100">
                      Advert Submitted 🎉
                    </button>
                    <div className="mt-5">
                      <h3>Thank You ❤️</h3>
                      <p className="lead">
                        Our team will review your submission and will be in
                        touch soon.
                      </p>
                      <Link
                        className="btn btn-sm btn-primary"
                        to="#top"
                        onClick={() => {
                          setMessage("");
                          reset();
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        Add Another
                      </Link>{" "}
                    </div>
                  </>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-block py-3 btn-secondary mt-2"
                  >
                    {site.createAdvertCTA}
                  </button>
                )}

                {/* {message && <h3 className="mt-4 h5">{message}</h3>} */}
              </form>
            </div>
            <div className={`col-11 col-lg-8 small mt-5`}>
              <div
                className={`small`}
                dangerouslySetInnerHTML={{
                  __html: site.createAdvertTerms,
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default InvitedCreate;
