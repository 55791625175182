import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import site from "./../_data/marketTheme.json";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

import { Link, useParams } from "react-router-dom";

import Loading from "../Components/General/Loading";
import Error from "../Components/General/Error";

import Line from "../Components/Charts/Line";
import YourApplicantsRow from "../Components/Marketplace/Applicants/YourApplicantsRow";
import YourSuppliersRow from "../Components/Marketplace/Applicants/YourSuppliersRow";

import { sumValue } from "../Components/Functions";

import {
  getOpportunityOwner,
  removeOpportunity,
  getApplicationsToOpportunities,
  getDashboard,
} from "../Components/API/API";

function YourListingTemplate({ user, companyId, company, meta, accessToken }) {
  const params = useParams();
  const opportunityId = params.advertId;

  const [dashboard, setDashboard] = useState(false);

  const [requestEdit, setRequestEdit] = useState(false);
  const [requestedDeletion, setRequestedDeletion] = useState(false);
  const [applications, setApplications] = useState(false);
  const [statusLive, setStatusLive] = useState(false);
  const [opportunity, setOpportunity] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!opportunity && companyId?.length) {
      getOpportunityOwner(opportunityId, user?.sub, accessToken, companyId)
        .then((res) => {
          // console.log(res.fields.status, companyId, res.fields.Company[0], companyId===res.fields.Company[0]);
          if (companyId === res.fields.Company[0]) {
            setOpportunity(res);
            setStatusLive(res.fields.status);
            getDashboard(user?.sub, accessToken, companyId)
              .then((res) => {
                setDashboard(res);
                setIsLoading(false);
              })
              .catch((error) => {
                setIsError(true);
              });

            getApplicationsToOpportunities(
              companyId,
              user?.sub,
              accessToken,
              false,
              false,
              opportunityId
            )
              .then((data) => {
                setApplications(data?.records);
                setIsLoading(false);
              })
              .catch((error) => {
                setIsLoading(false);
                setIsError(true);
              });
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setIsError(true);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [opportunity, opportunityId, meta.company, accessToken, companyId, user]);

  // TODO: check if your company matches the listing company, otherwise kill the page

  return (
    <>
      <Helmet>
        <title>
          {opportunity?.fields?.Name ? opportunity?.fields?.Name : "..."} /{" "}
          {site.yourListingsName} / {site.OpportunitiesName} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.yourListingsName,
            url: "/marketplace/listings/",
          },
          {
            title: opportunity?.fields?.Name
              ? opportunity?.fields?.Name
              : "...",
            url: "./",
          },
        ]}
      />
      <>
        <main className="">
          {isLoading ? (
            <Loading bg={"bg-" + site.tablePageBG} />
          ) : (
            <>
              {!meta.company ? (
                <Loading bg={"bg-" + site.tablePageBG} />
              ) : (
                <>
                  {!isError ? (
                    <>
                      <div className="container-fluid bg-light py-4">
                        <div className="row justify-content-center contained-xl pt-2">
                          <div className="col-lg-10">
                            {/* {opportunity.statusLive === "Live" && ( */}
                            <div className="row mb-2 mt-4">
                              <div className="col-12">
                                <h2 className="h4 font-family-display d-inline mr-2">
                                  {opportunity?.fields?.Name} Dashboard
                                </h2>
                                <div className="float-right">
                                  {statusLive === "Live" && (
                                    <Link
                                      className="btn ml-2 btn-outline-primary btn-sm small text-uppercase font-weight-bold"
                                      to={`/marketplace/opportunities/${opportunityId}`}
                                    >
                                      View Advert
                                    </Link>
                                  )}

                                  <button
                                    className="btn ml-2 btn-outline-warning btn-sm small text-uppercase font-weight-bold"
                                    onClick={() => {
                                      setRequestEdit(true);
                                    }}
                                  >
                                    Edit Advert
                                  </button>

                                  {requestEdit && (
                                    <div
                                      className="modal d-block"
                                      role="dialog"
                                    >
                                      <div
                                        className="modal-dialog"
                                        role="document"
                                      >
                                        <div className="modal-content">
                                          <div className="modal-header bg-primary">
                                            <h5 className="modal-title">
                                              Edit Your Advert
                                            </h5>
                                            <button
                                              type="button"
                                              className="btn btn-sm btn-secondary"
                                              data-dismiss="modal"
                                              aria-label="Close"
                                              onClick={() => {
                                                setRequestEdit(false);
                                              }}
                                            >
                                              <span aria-hidden="true">
                                                <i className="fa fa-times" />
                                              </span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <p>
                                              Hey there 👋,
                                              <br />
                                              <br /> We're currently working on
                                              adding the ability to edit your
                                              adverts, in the meantime
                                              <br />
                                              <br />
                                              If you wish to make a change, then
                                              please contact the PH team.
                                            </p>
                                            <p>
                                              <a
                                                href={`mailto:info@partnerhype.com?subject=PH Advert Edit Request&body=Advert: ${opportunity?.fields?.Name}`}
                                                className="btn btn-primary"
                                              >
                                                info@partnerhype.com
                                              </a>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {!requestedDeletion &&
                                  statusLive !== "Removed" ? (
                                    <button
                                      className="btn ml-2 btn-outline-danger btn-sm small text-uppercase font-weight-bold"
                                      onClick={() => {
                                        setRequestedDeletion(true);
                                        removeOpportunity(
                                          opportunityId,
                                          user.sub,
                                          accessToken
                                        );
                                        setStatusLive("Removed");
                                      }}
                                    >
                                      {opportunity.statusLive === "Live"
                                        ? "Cancel Advert"
                                        : "Cancel Request"}
                                    </button>
                                  ) : (
                                    <button
                                      className="btn mr-2 btn-danger btn-sm small text-uppercase font-weight-bold"
                                      disabled={true}
                                    >
                                      Cancelled
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4">
                              {dashboard && (
                                <div className="col-12 col-lg-9 mb-4">
                                  <div className="card h-100 rounded-lg">
                                    <div className="card-body">
                                      <Line data={dashboard} height={300} />
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-lg-3">
                                <div className="row">
                                  <div className="col-6 col-lg-12 mb-4 text-lg-right">
                                    <div className="card rounded-lg h-100">
                                      <div className="card-body">
                                        <div className="small text-uppercase">
                                          Accepted Leads
                                        </div>
                                        <div className="h4 mt-1 mb-1">
                                          {sumValue("accepted", dashboard)}
                                        </div>
                                        <div className="small">
                                          <span className="text-success">
                                            {(
                                              (sumValue("accepted", dashboard) /
                                                (sumValue(
                                                  "accepted",
                                                  dashboard
                                                ) +
                                                  sumValue(
                                                    "rejected",
                                                    dashboard
                                                  ) +
                                                  sumValue(
                                                    "pending",
                                                    dashboard
                                                  ) +
                                                  sumValue(
                                                    "quarantine",
                                                    dashboard
                                                  ))) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </span>{" "}
                                          of Leads
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 col-lg-12 mb-4 text-lg-right">
                                    <div className="card rounded-lg h-100">
                                      <div className="card-body">
                                        <div className="small text-uppercase">
                                          Rejected Leads
                                        </div>
                                        <div className="h4 mt-1 mb-1">
                                          {sumValue("rejected", dashboard)}
                                        </div>
                                        <div className="small">
                                          <span className="text-success">
                                            {(
                                              (sumValue("rejected", dashboard) /
                                                (sumValue(
                                                  "accepted",
                                                  dashboard
                                                ) +
                                                  sumValue(
                                                    "rejected",
                                                    dashboard
                                                  ) +
                                                  sumValue(
                                                    "pending",
                                                    dashboard
                                                  ) +
                                                  sumValue(
                                                    "quarantine",
                                                    dashboard
                                                  ))) *
                                              100
                                            ).toFixed(2)}
                                            %
                                          </span>{" "}
                                          of Leads
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 col-lg-12 mb-4 text-lg-right">
                                    <div className="card rounded-lg h-100">
                                      <div className="card-body">
                                        <div className="small text-uppercase">
                                          Delivered Percentages
                                        </div>
                                        <div className="h4 mt-1 mb-3 pb-1">
                                          {(
                                            (sumValue("accepted", dashboard) /
                                              parseInt(
                                                opportunity?.fields
                                                  ?.leadLimitTotal
                                              )) *
                                            100
                                          ).toFixed(2)}
                                          %
                                        </div>
                                        <div className="rounded py-1 bg-light overflow-hidden position-relative">
                                          <div
                                            className="position-absolute position-top py-2 bg-success"
                                            style={{
                                              width:
                                                (
                                                  (sumValue(
                                                    "accepted",
                                                    dashboard
                                                  ) /
                                                    company.agreedLeadLimits) *
                                                  100
                                                ).toFixed(2) + "%",
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 col-lg-12 mb-4 text-lg-right">
                                    <div className="card rounded-lg h-100 bg-info text-lg-right">
                                      <div className="card-body">
                                        <div className="small text-uppercase">
                                          YOUR Revenue
                                        </div>
                                        <div className="h4 mt-1 mb-3">
                                          &pound;
                                          {sumValue("revenue", dashboard)}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row small mt-4">
                              <div className="col-12">
                                <h3 className="h4 font-family-display">
                                  Your Suppliers
                                </h3>
                              </div>
                              {applications && applications?.length > 0 ? (
                                applications.map(function (application) {
                                  if (
                                    application.fields.status !== "Accepted" &&
                                    application.fields.status !== "Live"
                                  ) {
                                    return false;
                                  } else {
                                    return (
                                      <>
                                        <YourSuppliersRow
                                          key={application.id}
                                          {...application.fields}
                                          user={user}
                                          accessToken={accessToken}
                                          recId={application.id}
                                        />
                                      </>
                                    );
                                  }
                                })
                              ) : (
                                <div className="col-12">
                                  <p>No Suppliers found</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container-fluid py-4 bg-dark-primary">
                        <div className="row justify-content-center contained-xl pt-2">
                          <div className="col-lg-10">
                            <div className="row small mt-4 ">
                              <div className="col-12">
                                <h3 className="h4 font-family-display">
                                  Your Applicants
                                </h3>
                              </div>
                              {applications && applications?.length > 0 ? (
                                applications.map(function (application) {
                                  if (
                                    application.fields.status === "Accepted" ||
                                    application.fields.status === "Live"
                                  ) {
                                    return false;
                                  } else {
                                    return (
                                      <>
                                        <YourApplicantsRow
                                          dark={true}
                                          key={application.id}
                                          {...application.fields}
                                          viewing={opportunityId}
                                          user={user}
                                          accessToken={accessToken}
                                          recId={application.id}
                                        />
                                      </>
                                    );
                                  }
                                })
                              ) : (
                                <div className="col-12">
                                  <p>No Applications found</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Error />
                  )}
                </>
              )}
            </>
          )}
        </main>
      </>
    </>
  );
}
export default YourListingTemplate;
