import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import site from "../_data/marketTheme.json";
import Loading from "./../Components/General/Loading";
import Error from "./../Components/General/Error";

import { getContentApproval } from "./../Components/API/API";

import ContentApprovalDetail from "../Components/ContentApproval/ContentApprovalDetail";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

function ContentApprovalDetailTemplate({
  user,
  companyId,
  meta,
  accessToken,
  company,
}) {
  const params = useParams();
  const contentId = params.contentId;

  const [applications, setApplications] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!applications && companyId?.length) {
      getContentApproval({
        userId: user?.sub,
        token: accessToken,
        Identifier: contentId,
        opportunityCompany: companyId,
        companyType: "applyingCompany",
      })
        .then((data) => {
          setApplications(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
          console.log(error);
        });
    }
  }, [applications, meta.company, accessToken, companyId, user, contentId]);

  return (
    <>
      <Helmet>
        <title>
          {applications[0]?.fields?.Name ? applications[0].fields.Name : "..."}{" "}
          / {site.account} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: "Content Approval",
            url: "./../",
          },
          {
            title: applications[0]?.fields?.Name
              ? applications[0].fields.Name
              : "...",
            url: "./",
          },
        ]}
      />
      <div className="">
        {isLoading ? (
          <Loading bg={"bg-" + site.tablePageBG} />
        ) : (
          <>
            {!meta.company ? (
              <Loading bg={"bg-" + site.tablePageBG} />
            ) : (
              <>
                {!isError ? (
                  <ContentApprovalDetail {...applications[0].fields} />
                ) : (
                  <Error />
                )}
              </>
            )}
          </>
        )}
        ;
      </div>
    </>
  );
}
export default ContentApprovalDetailTemplate;
