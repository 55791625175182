import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

export default function App({ data, title, labels, label, legend }) {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const theme = {
    blue: "#3c4c9b",
    darkblue: "#0E1134",
    indigo: "#6610f2",
    purple: "#2d2c50",
    pink: "#e83e8c",
    red: "#f43e70",
    orange: "#ffa60d",
    yellow: "#ffba08",
    green: "#2ec4b6",
    teal: "#006992",
    cyan: "#20a4f3",
  };
  const options = {
    plugins: {
      title: {
        display: title,
        text: "Current progress",
      },
      legend: legend
        ? {
            position: "bottom",
          }
        : false,
    },
  };
  const dataSetReturn = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: data,
        backgroundColor: [
          theme.green,
          theme.darkblue,
          theme.blue,
          theme.red,
          theme.yellow,
          theme.purple,
          theme.orange,
        ],
        borderWidth: 0,
      },
    ],
  };

  return <Doughnut data={dataSetReturn} options={options} />;
}
