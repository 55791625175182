import React, { useState, useEffect } from "react";
import site from "./../../_data/marketTheme.json";
import { Link } from "react-router-dom";
import Confetti from "../General/Confetti";

function OpportunityApplyButton({
  apply,
  opportunity,
  meta,
  isAuthenticated,
  submitApplication,
  ViewerDDValid,
}) {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (opportunity?.fields?.applyingCompanies) {
      setIsActive(true);
    }
  }, [setIsActive, isActive, opportunity, meta]);

  return (
    <>
      {!isAuthenticated ? (
        <Link to="#" className={`btn btn-apply btn-block py-4 btn-success`}>
          ...
        </Link>
      ) : (
        <>
          {(opportunity.fields.opportunityDueDilForce && ViewerDDValid) ||
          !opportunity.fields.opportunityDueDilForce ? (
            <>
              {opportunity.fields.Company &&
              opportunity.fields.Company[0] !== meta.company ? (
                <div className="position-relative text-align-center">
                  {apply && !isActive ? (
                    <div
                      className={`btn btn-apply btn-block px-2 py-3 py-lg-4 ${
                        !isActive ? "btn-success" : "btn-secondary"
                      }`}
                      onClick={() => {
                        submitApplication();
                        setIsActive(true);
                      }}
                    >
                      {!isActive
                        ? "Apply to This Opportunity 👉"
                        : "👍 You've Applied 🎉"}
                    </div>
                  ) : !apply && isActive ? (
                    <div
                      className={`btn btn-apply btn-block px-2 py-3 py-lg-4 btn-secondary`}
                    >
                      👍 You've Applied 🎉
                    </div>
                  ) : isActive ? (
                    <div
                      className={`btn btn-apply btn-block px-2 py-3 position-relative py-lg-4 btn-secondary`}
                    >
                      <Confetti size="small" />
                      👍 You've Applied 🎉
                    </div>
                  ) : (
                    <Link
                      to={
                        !isActive
                          ? `/marketplace/opportunities/${opportunity.id}/apply/`
                          : "#"
                      }
                      className={`btn btn-apply btn-block px-2 py-3 py-lg-4 ${
                        !isActive ? "btn-success" : "btn-secondary"
                      }`}
                    >
                      {!isActive
                        ? "Apply to This Opportunity 👉"
                        : "👍 You've Applied 🎉"}
                    </Link>
                  )}
                </div>
              ) : (
                <div
                  className={`btn btn-apply btn-block py-4 btn-secondary disabled`}
                >
                  {site.OpportunitiesYourOwn}
                </div>
              )}
            </>
          ) : (
            <>
              <div className={`btn btn-apply btn-block py-4 btn-dark disabled`}>
                Valid Due Diligence Required
              </div>
              <p className="text-left mt-3">
                To apply to this opportunity, you need a valid Due Diligence
                Certificate. If You haven't already started the process do so
                now!
              </p>
              <p>
                <Link to="/due-diligence/" className="btn btn-sm btn-secondary">
                  Apply for DD Certification Now
                </Link>
              </p>
            </>
          )}
        </>
      )}
    </>
  );
}

export default OpportunityApplyButton;
