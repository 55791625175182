import React from "react";
import { Link } from "react-router-dom";
import { sumValue } from "../Functions";

import Doughnut from "../Charts/Doughnut";
import Icon from "../General/Icon";
import Confetti from "../General/Confetti";

function Onboarding({ leads, user, meta, company }) {
  const todo = {
    contactSurname: {
      title: "Setup Your Account",
      requirePost: false,
      requireApply: false,
      link: "/account/",
      completed: false,
    },
    postedOpportunities: {
      title: "Post An Advert",
      requirePost: true,
      requireApply: false,
      link: "/marketplace/opportunities/create/",
      completed: false,
    },
    postedOpportunitiesLive: {
      title: "Advert Live",
      requirePost: true,
      requireApply: false,
      link: "/marketplace/opportunities/",
      completed: false,
    },
    appliedOpportunities: {
      title: "Apply to an Advert",
      requirePost: false,
      requireApply: true,
      link: "/marketplace/opportunities/",
      completed: false,
    },
    postedOpportunitiesApplied: {
      title: "Receive Applications",
      requirePost: true,
      requireApply: false,
      link: "/marketplace/applications/",
      completed: false,
    },
    postedOpportunitiesAccepted: {
      title: "Accept a Partnership",
      requirePost: true,
      requireApply: false,
      link: "/marketplace/applications/",
      completed: false,
    },
    appliedOpportunitiesAccepted: {
      title: "Be Accepted for a Partnership",
      requirePost: false,
      requireApply: true,
      link: "/marketplace/applicants/",
      completed: false,
    },
    DD: {
      title: "Complete Due Diligence",
      requirePost: false,
      requireApply: true,
      link: "/due-diligence/apply/",
      completed: false,
    },
    DDValid: {
      title: "Pass Due Diligence",
      requirePost: false,
      requireApply: true,
      link: "/due-diligence/",
      completed: false,
    },
    leads: {
      title: "Generate Accepted Leads",
      requirePost: false,
      requireApply: true,
      link: false,
      completed: false,
    },
  };

  var todoList = [];

  for (const item in todo) {
    if (
      (!todo[item].requirePost && !todo[item].requireApply) ||
      (todo[item].requirePost && meta.postOpportunitiesTermsAccepted) ||
      (todo[item].requireApply && meta.generateLeadTermsAccepted)
    ) {
      if ((item === "leads" && leads) || company[item]) {
        todo[item].completed = true;
      }
      todoList.push(todo[item]);
    }
  }

  // if(!company.postedOpportunitiesAccepted) todo
  return (
    <header className={`bg-light pt-4 pt-lg-5`}>
      <div className="container-fluid pt-lg-3">
        <div className="row contained-xl justify-content-center">
          <div className="col-lg-10 align-content-center ">
            <div className="bg-primary rounded shadow p-4 p-lg-5">
              <div className="row">
                <div className="col-lg-9">
                  <div className="mb-4">
                    <div className="h2 font-family-display mb-3">
                      👋 Howdy {company.contactFirstName}!
                    </div>
                    <p className="small">
                      We're currently in Beta mode, but have a look around and
                      give us feedback.
                    </p>
                  </div>
                  <div className="row">
                    {todoList.map((item, i) => (
                      <div className="col-lg-6 h5 mb-3" key={`todo-${i}`}>
                        <Link to={item.link} className="text-white">
                          <Icon
                            icon={item.completed ? "square-check" : "square"}
                            type={item.completed ? "fas" : "far"}
                            className={`mr-3 ${
                              item.completed ? "text-success" : "text-danger"
                            }`}
                          />
                          <span
                            className={
                              item.completed
                                ? "text-decoration-line-through"
                                : ""
                            }
                          >
                            {item.title}
                          </span>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                {(sumValue("completed", todoList) / todoList.length) * 100 ===
                  100 && <Confetti />}
                <div className="col-lg-3 position-relative">
                  <div className="d-none d-lg-block">
                    <div
                      className="position-absolute text-center w-100"
                      style={{
                        top: "50%",
                        left: "0",
                        transform: "translateY(-80%)",
                      }}
                    >
                      <div className="h2 font-family-display mb-0 text-light">
                        {(
                          (sumValue("completed", todoList) / todoList.length) *
                          100
                        ).toFixed(0)}
                        %
                      </div>
                      <div className="small">COMPLETED</div>
                    </div>
                    <Doughnut
                      data={[
                        (sumValue("completed", todoList) / todoList.length) *
                          100,
                        100 -
                          (sumValue("completed", todoList) / todoList.length) *
                            100,
                      ]}
                      title={false}
                      legend={false}
                    />
                  </div>
                  <div className="d-lg-none bg-dark-primary w-100 position-relative py-3 rounded-lg overflow-hidden">
                    <div className="py-2 small text-primary text-center position-top h-100 w-100 z-index-100 position-absolute">
                      {(
                        (sumValue("completed", todoList) / todoList.length) *
                        100
                      ).toFixed(0)}
                      %
                    </div>
                    <div
                      className="bg-success py-2 small text-primary text-center h-100 position-top position-absolute"
                      style={{
                        width:
                          (sumValue("completed", todoList) / todoList.length) *
                            100 +
                          "%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default Onboarding;
