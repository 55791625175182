import React from "react";
//  { bg = "light" }
function Loading({mini=false, bg='bg-white'}) {
  return (
    <div className={bg}>
      <header className={`${mini ? 'w-100': 'position-absolute vw-100 vh-100'}`} style={{top:0}}>
        <div className="container h-100">
          <div className="row justify-content-center h-100">
            <div className="col-6 col-lg-8 text-center h-100 d-table">
              <div className="fa-2x  d-table-cell align-middle">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Loading;
