import React, { useState } from "react";
import Icon from "../General/Icon";

function Select({
  error,
  helper,
  handleChange = null,
  label = "",
  name = "",
  placeholder = label,
  value = "",
  defaultValue = false,
  type = "text",
  options = false,
  register,
  multiple = false,
  dark = true,
  validation,
  fullWidth = true,
  allowSelectAll = false,
}) {
  let theme =
    "custom-select custom-select-lg px-3 text-white bg-darker-primary";
  if (!dark) {
    theme = "custom-select custom-select-lg px-3 border-primary bg-light";
  }
  const [selectAll, setSelectAll] = useState(false);
  const [allOptions, setAllOptions] = useState([]);

  return (
    <div className="row mb-4 ">
      {label && (
        <label
          className={`${fullWidth ? "col-lg-12" : "col-lg-3"} align-self-top `}
        >
          <div className="font-weight-bold">
            {label}
            {allowSelectAll && (
              <div
                className="small btn btn-sm btn-outline-primary py-0 ml-2"
                onClick={() => {
                  setSelectAll(!selectAll);
                  setAllOptions(selectAll ? options : [] );
                  defaultValue = selectAll ? options : [false];
                }}
              >
                {!selectAll ? "Select All" : "De-Select All"}
              </div>
            )}
          </div>

          {helper && <div className="small">{helper}</div>}
        </label>
      )}
      <div className={fullWidth ? `col-lg-12` : `col-lg-9`}>
        <select
          id={name}
          className={theme}
          type={type}
          multiple={multiple}
          defaultValue={
            selectAll
              ? allOptions
              : defaultValue
              ? defaultValue
              : !placeholder
              ? label
              : placeholder
          }
          {...register(name ? name : label, { ...validation })}
        >
          {options.map((option, index) => (
            <option
              key={`option-${index}`}
              value={option === defaultValue && placeholder ? "" : option}
              disabled={option === defaultValue && placeholder ? true : false}
            >
              {option}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <div className="mt-2 col-12 text-right small opacity-80">
          <span className="px-2 py-1 bg-danger small rounded-sm">
            <Icon icon="xmark-circle" className="mr-1" />
            {error}
          </span>
        </div>
      )}
    </div>
  );
}
export default Select;
