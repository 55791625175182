import React from "react";
import LoginSignupButtons from "./../Authentication/LoginSignupButtons";

const Rescue = () => {
  return (
    <section className="bg-primary py-lg-5">
      <div className="container pb-5 text-center">
        <h2 className="h1 font-weight-normal">
          Join us now to see or post opportunities in our marketplace<span className="text-secondary">.</span>
        </h2>
        <LoginSignupButtons
          className="mt-4 btn-lg px-lg-5 py-3"
          realSignup={true}
          showLogin={false}
          textSignUp={"Join Early Now!"}
        />
      </div>
    </section>
  );
};

export default Rescue;
