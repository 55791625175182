import React, { useState } from "react";
import { Link } from "react-router-dom";

function YourListingsRow({
  recId,
  Name,
  sector,
  status,
  terms,
  viewing,
  user,
  accessToken,
}) {
  const [isViewing, setIsViewing] = useState(viewing === recId ? true : false);

  return (
    <div className="col-12 col-md-6 col-lg-12">
      <div className=" bg-white mb-3 px-4 py-4 px-lg-3 py-lg-2">
        <div className="row">
          <div className="col-12 mb-3 mb-lg-0 col-lg-4    align-self-center">
            <span className="font-weight-bold">{Name}</span>
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-4    align-self-center">
            <span className="d-lg-none text-uppercase mr-1 font-weight-bold">
              Sector:{" "}
            </span>
            <span>{sector}</span>
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2    text-lg-center  align-self-center">
            <span className={`my-1 my-lg-0 d-block`}>
              {status === "Pending" && (
                <span className="bg-warning px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-clock"></i> Pending
                </span>
              )}
              {(status === "Accepted" || status === "Live") && (
                <span className="d-lg-inline bg-success px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-check"></i> {status}
                </span>
              )}
              {(status === "Rejected" || status === "Removed") && (
                <span className="d-lg-inline bg-danger px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-xmark"></i> {status}
                </span>
              )}
            </span>
          </div>
          <div className="col-12 pt-2 pt-lg-0 col-lg-2 px-lg-2 text-lg-center align-self-center">
            <Link
              to={`/marketplace/listings/${recId}`}
              className={`btn d-block d-lg-inline-block btn-primary btn-sm py-0 mt-lg-1 text-nowrap`}
              onClick={function (e) {
                setIsViewing(!isViewing);
              }}
            >
              View
              <i className="ml-2 fa fa-chevron-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export default YourListingsRow;
