import React from "react";
import Search from "./Search";
import Card from "./Card";
import site from "../../_data/marketTheme.json";
import { Link } from "react-router-dom";
import Icon from "../General/Icon";

function OpportunitiesList({
  includeSearch,
  isLoading = false,
  title,
  titleClasses,
  nolarge,
  meta,
  user,
  company,
  setSearchDone,
  setCategory,
  setAudience,
  setSector,
  setPaymentModel,
  setCapabilities,
  setCountry,
  setApplied,
  category,
  audience,
  sector,
  paymentModel,
  capabilities,
  country,
  applied,
  opportunities,
  headerLevel = "h1",
}) {
  return (
    <main className={`${site.OpportunitiesBG}`}>
      <div className="container-fluid pt-5">
        <div className="row contained-xl justify-content-center">
          <div className={`col-lg-10`}>
            {headerLevel === "h1" ? (
              <h1 className={titleClasses}>{title}</h1>
            ) : (
              <div className={titleClasses}>
                {title}{" "}
                <Link
                  to="/marketplace/opportunities/"
                  className="btn btn-outline-primary btn-sm px-1 py-0 ml-3"
                >
                  Show all <Icon icon="chevron-right" className="mx-1" />
                </Link>
              </div>
            )}
          </div>
          <div className="col-12 mt-3">
            <div className="row justify-content-center">
              {includeSearch && (
                <Search
                  company={company}
                  setSearchDone={setSearchDone}
                  setCategory={setCategory}
                  setAudience={setAudience}
                  setSector={setSector}
                  setPaymentModel={setPaymentModel}
                  setCapabilities={setCapabilities}
                  setCountry={setCountry}
                  setApplied={setApplied}
                  opportunities={opportunities}
                  category={category}
                  audience={audience}
                  sector={sector}
                  paymentModel={paymentModel}
                  capabilities={capabilities}
                  country={country}
                  applied={applied}
                />
              )}
              <div className={`col-12 col-lg-${!includeSearch ? "10" : "8"}`}>
                <div className="row">
                  {/* {JSON.stringify(opportunities[0].id)} */}
                  {opportunities && opportunities.length ? (
                    <>
                      {opportunities.map((opportunity, i) => (
                        <Card
                          isLoading={isLoading}
                          {...opportunity.fields}
                          nolarge={nolarge}
                          key={
                            opportunity.id
                              ? "opportunity" + opportunity.id
                              : "loading-" + i
                          }
                          id={opportunity.id}
                          meta={meta}
                        />
                      ))}
                    </>
                  ) : (
                    <div className="col-12 col-lg-3 mb-4">
                      <p>No Results Found...</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default OpportunitiesList;
