import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Certificate from "../Components/LeadCertificate/LeadCertificate";
import Events from "./../Components/LeadCertificate/Events";
import Loading from "./../Components/General/Loading";
import Error from "./../Components/General/Error";

import site from "../_data/marketTheme.json";

function ViewCertificate() {
  const [lead, setLead] = useState(false);
  const [events, setEvents] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const leadId = params.leadId;

  useEffect(() => {
    if (leadId && !lead) {
      //TODO: move to function
      fetch(
        `https://api.airtable.com/v0/app9udXxMBagQoCVR/Leads?maxRecords=1&filterByFormula={affiliate_uid}="${leadId}"`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false);
          setLead(data.records);
          fetch(
            `https://api.airtable.com/v0/app9udXxMBagQoCVR/Events?filterByFormula={User}="${leadId}"`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
            .then((res) => res.json())
            .then((eventData) => {
              setIsLoading(false);
              if (
                eventData &&
                eventData?.records &&
                eventData?.records?.length > 0
              ) {
                // setIsError(true);
                setEvents(eventData.records.reverse());
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setIsError(true);
            });
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [leadId, lead, events]);

  return (
    <>
      <Helmet>
        <title>
          {site.leadCertificatesViewingTitle}{" "}
          {isLoading
            ? "loading..."
            : lead && lead[0]
            ? "Lead Certificate"
            : site.ErrorTitle}{" "}
          / {site.nav.leadCertificates} / {site.BrandName}
        </title>
      </Helmet>

      {leadId && (
        <>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!isError ? (
                <>
                  {lead && lead[0] ? (
                    <>
                      <Certificate l={lead[0]} events={events} />
                      {events ? (
                        <Events events={events} />
                      ) : (
                        <>
                          <div className="container py-5">
                            <h4>No Event Data Found</h4>
                            <p>
                              This lead has no event data, this can mean that
                              the lead was submitted without any events via an
                              API.
                            </p>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <Error />
                  )}
                </>
              ) : (
                <Error />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
export default ViewCertificate;
