import React from "react";

function Icon({ icon, type="fa", color = null, className = "mr-2" }) {
  return (
    <i
      className={`${className} ${color ? `text-${color}` : ``} ${type} fa-${icon}`}
    ></i>
  );
}
export default Icon;
