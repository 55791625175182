import React from "react";

function DetailsDetails({ split = false, terms = false, opportunity }) {
  return (
    <div className="row">
      <div className={`col-lg-${split ? 6 : 12} mt-4`}>
        <h2 className="h3 mb-2 mt-4">
          <span className="">{"..."}</span>
        </h2>
      </div>
      <div className={`col-lg-${split ? 6 : 12} mt-4`}>
        <h2 className="h5">Details</h2>
        <ul className="list-unstyled">
          <li className="pb-3 mb-2 border-bottom">
            <span className="font-weight-bold">Deadline</span>{" "}
            <span>{"..."}</span>
          </li>
          <li className="pb-3 mb-2 border-bottom">
            <span className="font-weight-bold">Sector</span>{" "}
            <span>{"..."}</span>
          </li>
          <li className="pb-3 mb-2 border-bottom">
            <span className="font-weight-bold">Category</span>{" "}
            <span>{"..."}</span>
          </li>
          <li className="pb-3 mb-2 border-bottom">
            <span className="font-weight-bold">Payment Model</span>{" "}
            <span>{"..."}</span>
          </li>
          <li className="pb-3 mb-2 border-bottom">
            <span className="font-weight-bold">Country</span>{" "}
            <span>{"..."}</span>
          </li>
          <li className="pb-3 mb-2 border-bottom">
            <span className="font-weight-bold">Capabilities</span>{" "}
            <span>{"..."}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DetailsDetails;
