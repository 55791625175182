import React, { useState, useEffect } from "react";
import Loading from "../General/Loading";
import Error from "../General/Error";
import site from "../../_data/marketTheme.json";
import TableData from "../Content/TableData";

import { getApplicationsToOpportunities } from "../API/API";

function YourApplicantsHome({
  user,
  companyId,
  meta,
  limitAmount,
  accessToken,
}) {
  const [certificates, setCertificates] = useState(false);
  const [viewInfo, setViewInfo] = useState({ id: false });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const limit = limitAmount ? `&limit=${limitAmount}` : "";

  useEffect(() => {
    if (!certificates && companyId?.length) {
      getApplicationsToOpportunities(companyId, user?.sub, accessToken, limit)
        .then((data) => {
          setCertificates(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [certificates, meta.company, accessToken, companyId, user, limit]);

  return (
    <>
      {isLoading ? (
        <Loading mini={true} bg={"bg-" + site.tablePageBG} />
      ) : (
        <>
          {!meta.company ? (
            <Loading bg={"bg-" + site.tablePageBG} />
          ) : (
            <>
              {!isError ? (
                <div className={`col-12`}>
                  {certificates?.records?.length > 0 ? (
                    <TableData
                      viewInfo={viewInfo}
                      setViewInfo={setViewInfo}
                      data={certificates?.records}
                      bgAlt="light"
                      accent="primary"
                      hideHeaders={true}
                      headers={[
                        {
                          title: "Advert",
                          mobileSize: 6,
                          desktopSize: 3,
                        },
                        {
                          title: "Company",
                          mobileSize: 6,
                          desktopSize: 3,
                        },
                        {
                          title: "DD",
                          mobileSize: 6,
                          desktopSize: 2,
                        },
                        {
                          title: "Status",
                          mobileSize: 6,
                          desktopSize: 2,
                          textAlign: "center",
                        },
                        {
                          title: "View",
                          mobileSize: 12,
                          desktopSize: 1,
                          textAlign: "center",
                        },
                      ]}
                      content={[
                        {
                          field: "opportunityName",
                          mobileSize: 6,
                          desktopSize: 3,
                          className: "font-weight-bold",
                        },
                        {
                          field: "applyingCompanyName",
                          mobileSize: 6,
                          desktopSize: 3,
                        },
                        {
                          field: "applyingCompanyDD",
                          mobileSize: 6,
                          desktopSize: 2,
                          type: "duediligence",
                        },
                        {
                          field: "status",
                          mobileSize: 6,
                          desktopSize: 3,
                          type: "status",
                          textAlign: "center",
                        },
                        {
                          link: "marketplace/applicants/",
                          final: true,
                        },
                      ]}
                    />
                  ) : (
                    <div className="row justify-content-center contained-xl">
                      <div className="col-12 pt-2">
                        <p>No one has applied yet</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <Error />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
export default YourApplicantsHome;
