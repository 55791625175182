import React from "react"; //useState
import { Helmet } from "react-helmet-async";
import Loading from "../Components/General/Loading";
// import OpportunitiesList from "../Components/Marketplace/List";
import HomeHero from "../Components/Home/HomeHero";
// import HomeContent from "./../Components/Home/HomeContent";
import connectImage from "../img/home.png";

// import { getOpportunities } from "../Components/API/API";

import FooterMain from "./../Components/Footer/Main";
import social from "../_data/social.json";
import contact from "../_data/contact.json";

import site from "./../_data/marketTheme.json";

function Home({
  user,
  meta,
  isAuthenticated,
  isLoading,
  accessToken,
  companyId,
  company,
}) {
  return (
    <>
      <Helmet>
        <title>
          {site.BrandName} - {site.BrandStrap}
        </title>
      </Helmet>

      {/* && !company.companyName ? */}
      {isLoading || (!isLoading && isAuthenticated && !company.companyName) ? (
        <Loading bg="darker-primary" />
      ) : (
        <>
          <HomeHero
            user={user}
            site={site}
            connectImage={connectImage}
            isAuthenticated={isAuthenticated}
          />
          {/* <HomeContent /> */}
          
          <FooterMain
            socials_title={social.title}
            facebook={social.facebook}
            twitter={social.twitter}
            instagram={social.instagram}
            linkedin={social.linkedin}
            footer_contact_title={contact.contact_title}
            footer_address={contact.address}
            footer_email={contact.email}
            footer_phone={contact.phone}
            footer_company_vat={contact.comp_vat}
            footer_menu_1_title={contact.footer_1_title}
            footer_menu_1_links={contact.footer_menu}
            footer_menu_2_title={contact.footer_2_title}
            footer_menu_2_links={contact.footer_menu_2}
          />
        </>
      )}
    </>
  );
}

export default Home;
