import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import BreadCrumb from "../Components/Navigation/BreadCrumb";
import Icon from "../Components/General/Icon";
import site from "../_data/marketTheme.json";
import Moment from "react-moment";

import Skeleton from "react-loading-skeleton";

function LeadCertificates({ isAuthenticated, meta }) {
  const [certificates, setCertificates] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [isError, setIsError] = useState(false);

  useEffect(() => {

    if (!certificates && isAuthenticated && meta.company) {
      // TODO: New Data Source

      fetch(
        `https://api.airtable.com/v0/app9udXxMBagQoCVR/Leads?filterByFormula={instance}="${meta.company}"`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_ACCESS_TOKEN}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false);
          setCertificates(data.records);
        })
        .catch((error) => {
          setIsLoading(false);
          // setIsError(true);
          setIsLoading(false);
          console.log(error);
        });
    }
  }, [
    certificates,
    isAuthenticated,
    meta.generateLeadTermsAccepted,
    meta.company,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {site.nav.leadCertificates} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.leadCertificatesName,
            url: "./",
          },
        ]}
      />

      <main className="loading bg-light vh-100 py-5">
        <div className="container-fluid">
          <div className="row contained-xl justify-content-center">
            {/* {isAuthenticated && meta.generateLeadTermsAccepted && ( */}
            <div className="col-10">
              <div className="row justify-content-center">
                
                {isAuthenticated && (
                  <div className="col-lg-12">
                    <h1 className="font-family-display mb-4">
                      {site.leadCertificatesName}
                    </h1>
                  </div>
                )}
                {isAuthenticated && (
                  <div className="col-lg-8">
                    {isLoading ? (
                      <>
                        <Skeleton className="py-2 mb-3" />
                        <Skeleton className="py-2 mb-3" />
                        <Skeleton className="py-2 mb-3" />
                        <Skeleton className="py-2 mb-3" />
                        <Skeleton className="py-2 mb-3" />
                        <Skeleton className="py-2 mb-3" />
                      </>
                    ) : (
                      <>
                        {(!certificates || !certificates.length) && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: site.leadCertificatesEmpty,
                            }}
                          ></p>
                        )}
                        {certificates && certificates.length ? (
                          <>
                            <div className="row contained-xl d-none d-lg-block">
                              <div className="col-12 pr-lg-5">
                                <div className="bg-primary mb-3 p-4 py-lg-2 px-lg-3 small font-weight-bold text-uppercase">
                                  <div className="row">
                                    <div className="col-12 col-lg-2 text-center text-lg-left align-self-center">
                                      Status
                                    </div>
                                    <div className="col-12 col-lg-4 text-center text-lg-left align-self-center">
                                      Certificate UID
                                    </div>
                                    <div className="col-6 col-lg-2 align-self-center">
                                      Received
                                    </div>
                                    <div className="col-6 col-lg-2 align-self-center">
                                      Updated
                                    </div>
                                    <div className="col-12 col-lg-2 text-center">
                                      View
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {certificates.map((certificate) => (
                              <React.Fragment key={certificate.id}>
                                {certificate.id &&
                                  certificate?.fields?.affiliate_uid && (
                                    <div className="row contained-xl ">
                                      <div className="col-12 pr-lg-5">
                                        <div className="bg-white mb-4 mb-lg-3 p-3 py-lg-2 px-lg-3">
                                          <div className="row">
                                            <div className="col-10 col-lg-4 order-lg-1 align-self-center small mb-4 mb-lg-0">
                                              <div className="d-lg-none font-weight-bold">
                                                Certificate UID
                                              </div>
                                              {certificate.fields.affiliate_uid}
                                            </div>
                                            <div className="col-2 col-lg-2 order-lg-0 mb-3 mb-lg-0 text-center align-self-center">
                                              {certificate.fields
                                                .lead_status === "Accepted" ||
                                              certificate.fields.lead_status ===
                                                "Received" ? (
                                                <Icon
                                                  icon="check"
                                                  color="success"
                                                />
                                              ) : (
                                                <Icon
                                                  icon="xmark"
                                                  color="secondary"
                                                />
                                              )}
                                            </div>
                                            <div className="col-6 col-lg-2 order-lg-2 align-self-center small mb-4 mb-lg-0">
                                              <div className="d-lg-none font-weight-bold">
                                                Received
                                              </div>
                                              <Moment format="DD/MM/YY hh:mma">
                                                {certificate.fields.received_at}
                                              </Moment>
                                            </div>
                                            <div className="col-6 col-lg-2 order-lg-3 align-self-center small mb-4 mb-lg-0">
                                              <div className="d-lg-none font-weight-bold">
                                                Updated
                                              </div>
                                              <Moment format="DD/MM/YY hh:mma">
                                                {
                                                  certificate.fields
                                                    .last_updated
                                                }
                                              </Moment>
                                            </div>
                                            <div className="col-12 col-lg-2 order-lg-4 text-lg-center align-self-center">
                                              <Link
                                                to={`/lead-certificate/${certificate.fields.affiliate_uid}`}
                                                className={`btn btn-sm btn-primary py-0 px-1 d-block d-lg-inline stretched-link`}
                                              >
                                                View
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </React.Fragment>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </div>
                )}
                <div
                  className={`col-12 col-lg-4 ${
                    !isAuthenticated ? "pt-4" : ""
                  }`}
                >
                  <div className="bg-primary p-4">
                    <h2 className="font-family-display h3 text-center">
                      {site.leadCertificatesSeachName}
                    </h2>
                    <p className="text-center">
                      {site.leadCertificatesSeachIntro}
                    </p>

                    <fieldset>
                      <div className="form-group">
                        <div className="input">
                          <input
                            className="form-control form-control-sm text-white text-center bg-darker-primary border-secondary"
                            type="text"
                            placeholder={site.leadCertificatesSeachField}
                            id="cert"
                          />
                        </div>
                      </div>
                    </fieldset>
                    <input
                      type="submit"
                      className="btn btn-block btn-sm btn-secondary mt-2"
                      value={site.leadCertificatesSeachCTA}
                      onClick={() => {
                        window.location.href =
                          "/lead-certificate/" +
                          document.getElementById("cert").value;
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </main>
    </>
  );
}
export default LeadCertificates;
