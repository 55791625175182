import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getCompany } from "./../API/API";

export function GetUser() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const [userMetadata, setUserMetadata] = useState({});
  const [userCompanyId, setUserCompanyId] = useState({});
  const [userCompany, setUserCompany] = useState({});
  const [token, setToken] = useState({});

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        });

        const userDetailsByIdUrl = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const { user_metadata } = await metadataResponse.json();
        await setUserMetadata(user_metadata);
        await setToken(accessToken);
        
        await getCompany(user.sub, accessToken)
          .then((res) => {
            setUserCompanyId(res?.id);
            setUserCompany(res?.fields);
          })
          .catch((error) => {
            console.log(error.message);
          });
      } catch (e) {
        console.log(e.message);
      }
    };

    if (isAuthenticated) {
      getUserMetadata();
    }
  }, [isAuthenticated, getAccessTokenSilently, user?.sub]);
  return {
    meta: userMetadata,
    user: user,
    companyId: userCompanyId,
    company: userCompany,
    isAuthenticated: isAuthenticated,
    isLoading: isLoading,
    accessToken: token,
  };
}
