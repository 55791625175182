import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../General/Loading";
import Error from "../../General/Error";
import site from "../../../_data/marketTheme.json";

import { getYourOpportunities } from "../../API/API";
import YourListingsRow from "./YourListingsRow";

function YourListings({ user, companyId, accessToken, meta, limitAmount }) {
  const params = useParams();
  const advertId = params.advertId;

  const [listings, setListings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!listings && companyId?.length) {
      getYourOpportunities(companyId, user?.sub, accessToken, null)
        .then((data) => {
          setListings(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
        });
    }
  }, [listings, meta.company, accessToken, companyId, user]);

  return (
    <>
      <div className="">
        {isLoading ? (
          <Loading bg={"bg-" + site.tablePageBG} />
        ) : (
          <>
            {!meta.company ? (
              <Loading bg={"bg-" + site.tablePageBG} />
            ) : (
              <>
                {!isError ? (
                  <div
                    className={`container-fluid bg-${site.tablePageBG} py-5 min-vh-100`}
                  >
                    <div className="row contained-xl">
                      <div className={`applicationBlock offset-lg-1 col-lg-10`}>
                        <div className="row justify-content-center contained-xl">
                          <div className="col-12">
                            <h1 className="mb-4">Your Adverts</h1>
                          </div>
                        </div>

                        <div className="d-none d-lg-block small col-12 p-0">
                          <div className="bg-primary mb-2 p-4 px-lg-3 py-lg-2">
                            <div className="row text-uppercase font-weight-bold">
                              <div className="col-6 col-lg-4  align-self-center font-weight-bold">
                                Opportunity
                              </div>
                              <div className="col-6 col-lg-4  align-self-center font-weight-bold">
                                Sector
                              </div>
                              <div className="col-12 col-lg-2  text-center align-self-center font-weight-bold">
                                Status
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row small">
                          {listings?.records?.length > 0 ? (
                            listings?.records.map((listing) => (
                              <YourListingsRow
                                key={listing.id}
                                {...listing.fields}
                                viewing={advertId}
                                recId={listing.id}
                                user={user}
                                accessToken={accessToken}
                              />
                            ))
                          ) : (
                            <div className="col-12 mt-3 px-4">
                              <p>No listings found</p>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-lg-10 offset-lg-1 mt-4 text-right">
                        <Link
                          to="/marketplace/opportunities/create"
                          className="btn btn-secondary btn-sm"
                        >
                          {site.nav.createAdvert}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Error />
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default YourListings;
