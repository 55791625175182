import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

export default function App({ data, height = 200, width = 100 }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Lead Flow",
      },
    },
  };

  const labels = Object.keys(data);
  const states = Object.keys(data[labels[0]]);

  const theme = {
    quarantine: "#3c4c9b",
    rejected: "#f43e70",
    pending: "#ffba08",
    accepted: "#2ec4b6",
  };

  const dataSet = [];

  states.map((state, i) => {
    if (state !== "revenue") {
      return dataSet.push({
        label: state,
        data: labels.map((date) => data[date][state]),
        borderColor: theme[state],
        backgroundColor: theme[state],
      });
    }
    else{
      return false;
    }
  });

  const dataSetReturn = {
    labels,
    datasets: dataSet,
  };

  return (
    <Line
      options={options}
      data={dataSetReturn}
      width={width}
      height={height}
    />
  );
}
