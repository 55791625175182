import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import site from "./../../_data/marketTheme.json";
import { useForm } from "react-hook-form";

import BreadCrumb from "../../Components/Navigation/BreadCrumb";

import { createOpportunity } from "./../../Components/API/API";
import { Link } from "react-router-dom";
import CreateForm from "../../Components/Marketplace/Listings/CreateForm";

function OpportunityCreate({ user, accessToken, company, companyId }) {
  const [message, setMessage] = useState("");
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ mode: "onBlur" });

  const watchOpportunityDateType = watch("opportunityDateType", false);

  useEffect(() => {
    if (companyId?.length) {
      reset({
        opportunityContactName: `${company.contactFirstName} ${company.contactSurname}`,
        opportunityContactCompany: company.companyName,
        opportunityContactEmail: user.email,
        opportunityContactPhone: company.companyPhone,
      });
    }
  }, [user, company, reset, companyId]);

  let handCreation = async (data) => {
    data.opportunityCompany = companyId;

    createOpportunity(data, user?.sub, companyId, accessToken)
      .then((res) => {
        setMessage("Created");
        reset({
          opportunityContactName: `${company.contactFirstName} ${company.contactSurname}`,
          opportunityContactCompany: company.companyName,
          opportunityContactEmail: user.email,
          opportunityContactPhone: company.companyPhone,
        });
      })
      .catch((error) => {
        setMessage("Error");
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {site.createAdvertName} / {site.OpportunitiesName} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.createAdvertName,
            url: "./",
          },
        ]}
      />
      <main className={`loading bg-${site.createAdvertBG} py-5`} id="start">
        <div className="container-fluid ">
          <div className="row contained-xl justify-content-center">
            <div className="col-11 col-lg-10 align-content-center">
              <div className={``}>
                <h2 className="font-family-display">{site.createAdvertName}</h2>
                <p className="mb-5">Takes about 5 minutes.</p>
              </div>

              <form onSubmit={handleSubmit(handCreation)}>
                {message !== "Created" && (
                  <CreateForm
                    register={register}
                    errors={errors}
                    watchOpportunityDateType={watchOpportunityDateType}
                  />
                )}

                {message === "Error" ? (
                  <>
                    <div className="mt-5">
                      <h3>There was an Error?</h3>
                      <p>
                        Unfortunately there was an error in submitting your
                        Advert.
                      </p>
                      <p>
                        Please review your details above. If this continues to
                        fail, please contact us{" "}
                        <a href="mailto:support@partnerhype.com">
                          support@partnerhype.com
                        </a>
                      </p>
                      <button
                        type="submit"
                        className="btn btn-block py-3 btn-secondary mt-2"
                      >
                        Try Again
                      </button>
                    </div>
                  </>
                ) : message === "Created" ? (
                  <>
                    <div className="btn btn-success py-3 btn-secondary mt-2 w-100">
                      Advert Submitted 🎉
                    </div>
                    <div className="mt-5">
                      <h3>Thank You ❤️</h3>
                      <p className="lead">
                        Our team will review your submission and will be in
                        touch soon.
                      </p>
                      <Link
                        className="btn btn-sm btn-primary"
                        to="#top"
                        onClick={() => {
                          setMessage("");
                          reset();
                          window.scrollTo({ top: 0 });
                        }}
                      >
                        Add Another
                      </Link>{" "}
                      <Link
                        className="btn btn-sm btn-secondary"
                        to="/marketplace/listings/"
                      >
                        View all Your Adverts
                      </Link>
                    </div>
                  </>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-block py-3 btn-secondary mt-2"
                  >
                    {site.createAdvertCTA}
                  </button>
                )}
              </form>
            </div>
            <div className={`col-11 col-lg-10 small mt-5`}>
              <div
                className={`small`}
                dangerouslySetInnerHTML={{
                  __html: site.createAdvertTerms,
                }}
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default OpportunityCreate;
