import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

import Loading from "../General/Loading";
import Error from "../General/Error";
import site from "../../_data/marketTheme.json";

import Input from "../Forms/Input";

import { getContentApproval } from "../API/API";
// import YourApplicantsRow from "./YourApplicantsRow";
import ContentApprovalCard from "./ContentApprovalCard";

import Skeleton from "react-loading-skeleton";

function ContentApproval({
  user,
  companyId,
  accessToken,
  meta,
  limitAmount,
  appId,
}) {
  const {
    register,
    watch,
    formState: { errors },
    // handleSubmit,
    // reset,
  } = useForm({ mode: "onBlur" });

  const watchSearch = watch("search", false);

  const [applications, setApplications] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const limit = limitAmount ? `&limitAmount=${limitAmount}` : "";

  useEffect(() => {
    if (!applications && companyId?.length) {
      getContentApproval({
        userId: user?.sub,
        token: accessToken,
        opportunityCompany: companyId,
        companyType: "applyingCompany",
      })
        .then((data) => {
          setApplications(data);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setIsError(true);
          console.log(error);
        });
    }
  }, [applications, meta.company, appId, accessToken, companyId, user, limit]);

  var currentStatus,
    newStatus = "";
  return (
    <>
      <div className="">
        {!meta.company ? (
          <Loading bg={"bg-" + site.tablePageBG} />
        ) : (
          <>
            {!isError ? (
              <div
                className={`container-fluid bg-${site.tablePageBG} py-5 min-vh-100`}
              >
                <div className="row contained-xl">
                  <div className={`applicationBlock offset-lg-1 col-lg-10`}>
                    <div className="row justify-content-center contained-xl">
                      <div className="col-lg-9">
                        <h1 className="mb-0 font-weight-normal">
                          Content Approval
                        </h1>
                      </div>
                      <div className="col-lg-3 pt-4 pt-lg-0 text-right">
                        <Input
                          name="search"
                          placeholder="Search..."
                          error={errors?.dueDiligenceCompany?.message}
                          register={register}
                          dark={false}
                          label={false}
                          type="text"
                          theme={false}
                          className={"form-control-sm px-0 mb-0"}
                        />
                      </div>
                    </div>

                    {/* <h2 className="h3">{currentStatus}</h2> */}

                    <div className="row mt-5">
                      {isLoading ? (
                        <>
                          <div className="col-lg-12 mb-2 mt-4">
                            <h2 className="h4">
                              <Skeleton className="py-3 mb-4" count={0.3}/>
                            </h2>
                          </div>
                          {[0, 0, 0, 0, 0, 0].map((application, i) => (
                            <div
                              className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                              key={`loading-${i}`}
                            >
                              <ContentApprovalCard
                                loading={true}
                                Status={<Skeleton />}
                                opportunityName={[<Skeleton count={0.5} />]}
                                // Attachment={
                                //   <Skeleton
                                //     style={{
                                //       height: "100%",
                                //       position: "absolute",
                                //       top: "0px",
                                //       objectFit: "cover",
                                //       objectPosition: "center center",
                                //     }}
                                //   />
                                // }
                                applyingCompanyName={<Skeleton count={0.5} />}
                                Name={<Skeleton  count={0.5}/>}
                                Description={<Skeleton  count={0.5}/>}
                                Identifier={<Skeleton />}
                              />
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {applications?.length > 0 ? (
                            applications.map((application, i) => {
                              if (
                                application.fields.ContentUID.search(
                                  watchSearch
                                ) > -1
                              ) {
                                if (
                                  currentStatus !== application.fields.Status
                                ) {
                                  currentStatus = application.fields.Status;
                                  newStatus = (
                                    <div className="col-lg-12 mb-2 mt-4">
                                      <h2 className="h4">
                                        {application.fields.Status}
                                      </h2>
                                    </div>
                                  );
                                } else {
                                  newStatus = "";
                                }
                                return (
                                  <React.Fragment key={`content-${i}`}>
                                    {newStatus}
                                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                                      <ContentApprovalCard
                                        CTA="View"
                                        {...application.fields}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              } else {
                                return false;
                              }
                            })
                          ) : (
                            <div className="col-12 mt-3 px-4">
                              <p>No Content Approvals Found</p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Error />
            )}
          </>
        )}
      </div>
    </>
  );
}
export default ContentApproval;
