import React, { useState } from "react";
import YourApplicantsDetail from "./YourApplicantsDetail";
import DueDiligenceIcon from "../../DueDiligence/DueDiligenceIcon";
import Bar from "../../Charts/Bar";

function YourSuppliersRow({
  recId,
  applyingCompany,
  applyingCompanyName,
  opportunityName,
  opportunityCategory,
  opportunitySector,
  opportunityCompany,
  status,
  opportunityTerms,
  instructions,
  opportunity,
  viewing,
  leadLimitFinal,
  applyingCompanyDD,
  applyingCompanyDDValid,
  applyingCompanyDDID,
  user,
  accessToken,
  applyingCompanyContact,
  applyingCompanyAddress,
  applyingCompanyPhone,
  applyingCompanyEmail,
  applicationNote,
  updated,
}) {
  const [isViewing, setIsViewing] = useState(viewing === recId ? true : false);
  const [statusLive, setStatusLive] = useState(status);

  return (
    <div className="col-12">
      <div className=" bg-white mb-3 px-4 py-4 px-lg-3 py-lg-2">
        <div className="row">
          <div className="col-6 mb-3 mb-lg-0 col-lg-2 align-self-center">
            <span className="d-block d-lg-none text-uppercase mr-1 font-weight-bold">
              Company:{" "}
            </span>
            <span className="font-weight-bold">{applyingCompanyName}</span>
          </div>
          <div className="col-12 px-0 pr-lg-3 mb-3 mb-lg-0 col-lg-4 align-self-center">
            <Bar />
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2  align-self-center">
            <span className="d-lg-none text-uppercase mr-1 font-weight-bold">
              Due Diligence:{" "}
            </span>
            <DueDiligenceIcon cert={applyingCompanyDDID} />
          </div>
          <div className="col-6 mb-3 mb-lg-0 col-lg-2    text-lg-center  align-self-center">
            <span className={`my-1 my-lg-0 d-block`}>
              {statusLive === "Pending" && (
                <span className="bg-warning px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-clock"></i> Pending
                </span>
              )}
              {(statusLive === "Accepted" || statusLive === "Live") && (
                <span className="d-lg-inline bg-success px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-check"></i> {statusLive}
                </span>
              )}
              {(statusLive === "Rejected" || statusLive === "Removed") && (
                <span className="d-lg-inline bg-danger px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                  <i className="fa fa-xmark"></i> {statusLive}
                </span>
              )}
            </span>
          </div>
          <div className="col-12 pt-2 pt-lg-0 col-lg-2 px-lg-2 text-lg-center align-self-center">
            <button
              className={`btn d-block d-lg-inline-block btn-primary btn-sm py-0 mt-lg-1 px-1 text-nowrap`}
              onClick={function (e) {
                setIsViewing(!isViewing);
              }}
            >
              Details{" "}
              {isViewing ? (
                <i className="ml-2 fa fa-chevron-up"></i>
              ) : (
                <i className="ml-2 fa fa-chevron-down"></i>
              )}
            </button>
          </div>
        </div>
        {isViewing && (
          <YourApplicantsDetail
            id={recId}
            opportunityName={opportunityName}
            opportunityCategory={opportunityCategory}
            opportunitySector={opportunitySector}
            opportunityCompany={opportunityCompany}
            statusLive={statusLive}
            setStatusLive={setStatusLive}
            opportunityTerms={opportunityTerms}
            instructions={instructions}
            opportunity={opportunity}
            viewing={viewing}
            user={user}
            leadLimitFinal={leadLimitFinal}
            applyingCompany={applyingCompany}
            applyingCompanyDDID={applyingCompanyDDID}
            accessToken={accessToken}
            applyingCompanyName={applyingCompanyName}
            applyingCompanyContact={applyingCompanyContact}
            applyingCompanyAddress={applyingCompanyAddress}
            applyingCompanyPhone={applyingCompanyPhone}
            applyingCompanyEmail={applyingCompanyEmail}
            applyingCompanyDD={applyingCompanyDD}
            applyingCompanyDDValid={applyingCompanyDDValid}
            applicationNote={applicationNote}
            updated={updated}
          />
        )}
      </div>
    </div>
  );
}
export default YourSuppliersRow;
