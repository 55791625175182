import React from "react";

import Icon from "../General/Icon";

import site from "../../_data/marketTheme.json";

function Certificate({ l, events }) {
  return (
    <div className="bg-light">
      <header className="bg-dark-primary pt-4 pb-3">
        <div className="container pt-lg-2">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <h2 className="h5 mb-0">
                {site.BrandName} Certificate:{" "}
                <span className="d-block d-lg-inline text-secondary">
                  {l.fields.affiliate_uid}
                </span>
              </h2>
            </div>
          </div>
        </div>
      </header>

      <section className="container my-4 text-center  text-lg-left my-lg-5">
        <div className="row justify-content-center">
          <div className="col-lg-6 align-self-center">
            <h3>Certificate Overview</h3>
            <h4 className="h6">
              Lead {l.fields.lead_status}{" "}
              {l.fields.lead_status === "Accepted" ||
              l.fields.lead_status === "Received" ? (
                <Icon icon="check" />
              ) : (
                <Icon icon="xmark" />
              )}
              <br />
              <span className="text-secondary">@</span> {l.fields.received_at}
            </h4>
            <p>
              This certificate represents a single consumer journey, from advert
              to form submission.{" "}
            </p>
            {l.fields.lead_status === ("Accepted" || "Receive") ? (
              <span className="bg-success rounded p-2 font-weight-bold">
                <Icon color="white" icon="circle-check" />
                Everything looks good with this submission.
              </span>
            ) : (
              <span className="bg-secondary rounded p-2 font-weight-bold">
                <Icon color="white" icon="circle-xmark" />
                It looks like there is an issue with this submission.
              </span>
            )}
          </div>
          <div className="col-lg-5 offset-lg-1 ">
            <div className="bg-white mt-5 mt-lg-0 rounded p-4 text-center text-lg-right">
              <h2 className="h4 mb-3">
                Your Lead Generator
              </h2>
              <img
                src={l.fields.companyLogo[0].url}
                alt={l.fields.companyName}
                className="img-fluid mb-2 p-0 col-6 col-lg-6"
              />
              <div>{l.fields.companyAddress}</div>
              <div>{l.fields.companyPhone}</div>
              <div>{l.fields.companyEmail}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="small container mt-lg-5">
        <div className="row justify-content-center">
          {events && events[0]?.fields && (
            <div className="col-lg-6 bg-white p-4">
              <div className="row">
                <div className="col-6 col-lg-4">
                  <h3 className="h6">Device</h3>
                </div>
                <div className="col-6 col-lg-8">
                  {events[0].fields.DeviceType === "desktop" ? (
                    <Icon icon="desktop" />
                  ) : (
                    <Icon icon="mobile" />
                  )}
                  {events[0].fields.DeviceType}
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-lg-4">
                  <h3 className="h6">Access</h3>
                </div>
                <div className="col-6 col-lg-8">
                  <Icon icon="globe" />
                  {events[0].fields.ClientType}
                </div>
              </div>

              <div className="row">
                <div className="col-6 col-lg-4">
                  <h3 className="h6">Browser</h3>
                </div>
                <div className="col-6 col-lg-8">
                  <Icon icon="globe" />
                  {events[0].fields.Browser}
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-6 bg-white p-4">
            <div className="row">
              <div className="col-6 col-lg-4">
                <h3 className="h6">Email Opt-In</h3>
              </div>
              <div className="col-6 col-lg-8">
                {l.fields.optin_email ? (
                  <Icon icon="circle-check" />
                ) : (
                  <Icon icon="circle-xmark" />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-4">
                <h3 className="h6">Phone Opt-In</h3>
              </div>
              <div className="col-6 col-lg-8">
                {l.fields.optin_phone ? (
                  <Icon icon="circle-check" />
                ) : (
                  <Icon icon="circle-xmark" />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-4">
                <h3 className="h6">SMS Opt-In</h3>
              </div>
              <div className="col-6 col-lg-8">
                {l.fields.optin_sms ? (
                  <Icon icon="circle-check" />
                ) : (
                  <Icon icon="circle-xmark" />
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6 col-lg-4">
                <h3 className="h6">Postal Opt-In</h3>
              </div>
              <div className="col-6 col-lg-8">
                {l.fields.optin_postal ? (
                  <Icon icon="circle-check" />
                ) : (
                  <Icon icon="circle-xmark" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Certificate;
