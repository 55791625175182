import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { Link } from "react-router-dom";
import site from "./../../_data/marketTheme.json";

function LoginSignupButtons({
  showSignup = true,
  showLogin = true,
  realSignup = false,
  size = "",
  color = "secondary",
  className = "mx-2 px-3 p-2",
  textSignUp = site.signup,
  textLogin = site.login,
}) {
  //loginWithRedirect loginWithPopup
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();

  return (
    <>
      {isLoading && <div className={`${className} pt-1`}>...</div>}
      {!isLoading && !isAuthenticated && (
        <>
          {realSignup ? (
            <button
              className={`btn ${
                size ? "btn-" + size : ""
              } ${className} btn-${color}`}
              onClick={() =>
                loginWithRedirect({
                  screen_hint: "signup",
                })
              }
            >
              {textSignUp}
            </button>
          ) : (
            <a
              className={`btn ${
                size ? "btn-" + size : ""
              } ${className} btn-${color}`}
              href="https://databowl.typeform.com/partner-hype"
            >
              {textSignUp}
            </a>
          )}
          {showLogin && (
            <button
              className={`btn ${
                size ? "btn-" + size : ""
              } ${className} btn-outline-${color}`}
              onClick={() => loginWithRedirect()}
            >
              {textLogin}
            </button>
          )}
        </>
      )}
      {!isLoading && isAuthenticated && (
        <>
          <button
            className={`btn ${
              size ? "btn-" + size : ""
            } ${className} btn-${color}`}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            {site.logout}
          </button>
        </>
      )}
    </>
  );
}
export default LoginSignupButtons;
