import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

export default function App({ data, ChartTitle }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: ChartTitle,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
  const labels = Object.keys(data);
  const states = Object.keys(data[labels[0]]);

  const theme = {
    quarantine: "#3c4c9b",
    rejected: "#f43e70",
    pending: "#ffba08",
    accepted: "#2ec4b6",
  };

  const dataSet = [];

  states.map((state, i) =>
    dataSet.push({
      label: state,
      data: labels.map((date) => data[date][state]),
      borderColor: theme[state],
      backgroundColor: theme[state],
    })
  );

  const dataSetReturn = {
    labels,
    datasets: dataSet,
  };

  return <Bar options={options} data={dataSetReturn} height={200} />;
}
