import React from "react";
import unVerifiedMiniBadge from "./../../img/UNVERIFIED.svg";
import verifiedMiniBadge from "./../../img/VERIFIED.svg";

function DueDiligenceIcon({ cert, small = true, className }) {
  let state = cert ? true : false;

  return (
    <img
      src={state ? verifiedMiniBadge : unVerifiedMiniBadge}
      alt={state ? "Verified" : "Unverified"}
      style={small && { maxWidth: "100px" }}
      className={className}
    />
  );
}
export default DueDiligenceIcon;
