import React from "react";
import LoginSignupButtons from "./LoginSignupButtons";

function UnAuthentication({ bg }) {
  return (
    <section className={bg ? `bg-${bg}` : ""}>
      <header className="py-5 vh-100">
        <div className="container-fluid">
          <div className="row contained-xl justify-content-center">
            <div className="col-6 col-lg-10">
              <div className="fa-3x">
                <h2>You need to be logged in to view this...</h2>
                <LoginSignupButtons />
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
}
export default UnAuthentication;
