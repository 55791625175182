import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb({ crumbs }) {
  return (
    <>
    <nav className={`breadcrumb bg-white position-fixed container-fluid mt-md-3 mt-lg-0`}>
      <div className={`row contained-xl justify-content-center align-self w-100`}>
        <div className="col-lg-10 small font-weight-bold py-1">
          {crumbs.map((crumb, i) => (
            <React.Fragment key={`breadcrumb-${i}`}>
              {crumb.url ? (
                <Link to={crumb.url} className="">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: crumb.title,
                    }}
                  />
                </Link>
              ) : (
                <span
                  className="mr-2 text-primary"
                  dangerouslySetInnerHTML={{
                    __html: crumb.title,
                  }}
                />
              )}
              {i > 0 && i !== crumbs.length - 1 && (
                <span className="mx-1">/</span>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </nav>
    <div className="pb-5 pb-lg-4"></div>
    </>
  );
}
export default BreadCrumb;
