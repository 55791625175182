import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import Loading from "./../Components/General/Loading";
import Error from "../Components/General/Error";

import { getDDList } from "../Components/API/API";

import site from "./../_data/marketTheme.json";
import BreadCrumb from "../Components/Navigation/BreadCrumb";
import DueDiligenceIcon from "../Components/DueDiligence/DueDiligenceIcon";

function DueDiligence({ user, company, companyId, accessToken }) {
  const [certificates, setCertificates] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!certificates && company?.DD && companyId?.length) {
      getDDList(user?.sub, companyId, accessToken)
        .then((data) => {
          setCertificates(data);
          setIsLoading(false);
          return data;
        })
        .catch(() => {
          setIsError(true);
        });
    } else if (!company?.DD && companyId?.length) {
      setIsLoading(false);
    }
  }, [user, certificates, accessToken, companyId, company]);

  return (
    <>
      <Helmet>
        <title>
          {site.nav.dueDiligence} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.dueDiligenceName,
            url: "./",
          },
        ]}
      />
      <div className="">
        {isLoading ? (
          <Loading bg="dark-primary" />
        ) : (
          <>
            {!isError ? (
              <>
                {certificates?.length > 0 ? (
                  <div
                    className={`container-fluid bg-${site.tablePageBG} py-4 py-lg-5`}
                  >
                    <>
                      <div className="row justify-content-center contained-xl">
                        <div className="col-12 col-lg-10">
                          <h1 className="font-family-display mb-4">
                            {site.dueDiligenceName}
                          </h1>
                        </div>
                      </div>
                      <div className="row contained-xl justify-content-center align-content-center">
                        <div className="col-lg-10 ">
                          <div className="row">
                            <div className="col-lg-8 pr-lg-5 small">
                              <div className="bg-primary mb-3 p-4 py-lg-2 px-lg-3 small font-weight-bold text-uppercase d-none d-lg-block">
                                <div className="row">
                                  <div className="col-md-3 text-center text-md-left align-self-center">
                                    Company
                                  </div>
                                  <div className="col-md-2 text-center text-md-left align-self-center">
                                    Application Date
                                  </div>
                                  <div className="col-md-2 text-center align-self-center">
                                    Expiry Date
                                  </div>
                                  <div className="col-md-3 text-md-center align-self-center">
                                    Status
                                  </div>
                                  <div className="col-md-2 text-lg-center">
                                    View
                                  </div>
                                </div>
                              </div>
                              {certificates.map((cert) => (
                                <div
                                  className={`bg-${site.tablePageALT2BG} p-2 px-3 mb-3`}
                                  key={cert.fields.id}
                                >
                                  <div className="row">
                                    <div className="col-12 col-md-3 align-self-center text-center text-lg-left d-none d-lg-block">
                                      <span className="font-weight-bold ">
                                        {cert.fields.dueDiligenceCompany}
                                      </span>
                                    </div>
                                    <div className="col-4 col-md-2 text-center align-self-center">
                                      {cert.fields.dueDiligenceApplicationDate}
                                    </div>
                                    <div className="col-4 col-md-2 text-lg-center align-self-center">
                                      {cert.fields.validUntil !==
                                        "1970-01-01" && cert.fields.validUntil}
                                    </div>
                                    <div className="col-4 col-md-3 mt-1 mt-lg-0 align-self-center text-center mb-2 mb-lg-0">
                                      {cert.fields.Status === "Processing" && (
                                        <span className="bg-warning px-2 py-1 rounded text-uppercase small font-weight-bold text-center">
                                          <i className="fa fa-clock"></i>{" "}
                                          Processing
                                        </span>
                                      )}
                                      {cert.fields.Status === "Pending" && (
                                        <span className="bg-primary px-2 py-1 rounded text-uppercase small font-weight-bold text-center">
                                          <i className="fa fa-clock"></i>{" "}
                                          Pending
                                        </span>
                                      )}
                                      {cert.fields.Status === "Live" && (
                                        <DueDiligenceIcon
                                          cert={cert.fields.valid}
                                          className={`shadow`}
                                        />
                                      )}
                                    </div>
                                    <div className="col-12 col-md-2 text-lg-center align-self-center">
                                      <Link
                                        to={`/due-diligence/${cert.id}`}
                                        className="btn btn-sm d-block d-lg-inline-block btn-primary stretched-link mt-3 mt-lg-0 py-0 px-1"
                                      >
                                        {site.dueDiligenceViewCTA}
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-12 col-lg-4">
                              <div className="bg-primary p-4 shadow text-center">
                                <h2 className="font-family-display h3 mb-4">
                                  {certificates.length
                                    ? site.dueDiligenceReApply
                                    : site.dueDiligenceApply}
                                </h2>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: site.dueDiligenceReApplyNote,
                                  }}
                                />
                                <Link
                                  to="/due-diligence/apply/"
                                  className="btn btn-sm btn-secondary btn-block"
                                >
                                  Apply Now
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                ) : (
                  <div
                    className={`container-fluid bg-${site.tablePageBG} py-5`}
                  >
                    <div className="row contained-xl justify-content-center align-content-center small">
                      <div className="col-12 col-lg-10">
                        <h1 className="font-family-display mb-4">
                          Due Diligence Certificates
                        </h1>
                        <p className="lead">
                          Start your Due Diligence Certification Process Today.
                        </p>
                        <Link
                          to="/due-diligence/apply/"
                          className="btn btn-secondary btn-sm"
                        >
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Error />
            )}
          </>
        )}
      </div>
    </>
  );
}
export default DueDiligence;
