import React, { useState, useEffect } from "react"; //useState
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import site from "./../_data/marketTheme.json";
import Skeleton from "react-loading-skeleton";

import {
  getOpportunities,
  getDashboard,
  getDashboardCampaignSummary,
} from "../Components/API/API";

import OpportunitiesList from "../Components/Marketplace/List";
import Loading from "../Components/General/Loading";

import BreadCrumb from "../Components/Navigation/BreadCrumb";

import YourApplicationsHome from "../Components/Dashboard/YourApplicationsHome";
import YourApplicantsHome from "../Components/Dashboard/YourApplicantsHome";
import YourAdvertsHome from "../Components/Dashboard/YourAdvertsHome";
// import Bar from "../Components/Charts/Bar";
import StackedBar from "../Components/Charts/StackedBar";
import Line from "../Components/Charts/Line";
import Onboarding from "../Components/Dashboard/Onboarding";

// import CMSContent from "../Components/General/CMSContent";

import { sumValue } from "../Components/Functions";
import Icon from "../Components/General/Icon";

function Dashboard({
  user,
  meta,
  isAuthenticated,
  isLoading,
  accessToken,
  companyId,
  company,
}) {
  const loadingData = {
    fields: {
      id: <Skeleton />,
      meta: <Skeleton />,
      applyingCompanies: false,
      name: <Skeleton />,
      Name: <Skeleton />,
      ShortDesc: <Skeleton />,
      companyName: <Skeleton />,
      featured: <Skeleton />,
      nolarge: <Skeleton />,
    },
  };

  const [opportunities, setOpportunities] = useState(false);
  const [dashboardCampaignSummary, setDashboardCampaignSummary] =
    useState(false);

  const [dashboard, setDashboard] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (
      accessToken &&
      isLoadingData &&
      isAuthenticated &&
      meta?.generateLeadTermsAccepted &&
      companyId?.length
    ) {
      getOpportunities(
        user?.sub,
        accessToken,
        companyId,
        `, FIND("${meta.company}",{applyingCompanies})=0`,
        4
      )
        .then((res) => {
          setOpportunities(res);
          setIsLoadingData(false);
        })
        .catch((error) => {
          setIsError(true);
        });

      getDashboard(user?.sub, accessToken, companyId)
        .then((res) => {
          setDashboard(res);
          setIsLoadingData(false);
        })
        .catch((error) => {
          setIsError(true);
        });

      getDashboardCampaignSummary(user?.sub, accessToken, companyId)
        .then((res) => {
          setDashboardCampaignSummary(res);
          setIsLoadingData(false);
        })
        .catch((error) => {
          setIsError(true);
        });
    }
  }, [
    isAuthenticated,
    opportunities,
    isLoadingData,
    accessToken,
    meta,
    user,
    companyId,
    company,
  ]);

  return (
    <>
      <Helmet>
        <title>
          {site.BrandName} - {site.BrandStrap}
        </title>
      </Helmet>

      {/* && !company.companyName ? */}
      {isLoading || (!isLoading && isAuthenticated && !company?.companyName) ? (
        <Loading bg="darker-primary" />
      ) : (
        <>
          {isAuthenticated && company.companyName && (
            <>
              <BreadCrumb
                crumbs={[
                  { title: `${site.Browsing}:` },
                  {
                    title: `Dashboard`,
                    url: "./",
                  },
                ]}
              />
              <Onboarding
                company={company}
                user={user}
                meta={meta}
                leads={sumValue("accepted", dashboard)}
              />

              {meta?.generateLeadTermsAccepted && (
                <>
                  {!isLoadingData && opportunities ? (
                    <OpportunitiesList
                      title={site.OpportunitiesLatest}
                      titleClasses="h4 font-family-display"
                      nolarge={true}
                      meta={meta}
                      site={site}
                      isLoading={isLoadingData}
                      isError={isError}
                      includeSearch={false}
                      opportunities={opportunities}
                      headerLevel="h2"
                    />
                  ) : (
                    <OpportunitiesList
                      title={site.OpportunitiesLatest}
                      titleClasses="h4 font-family-display"
                      nolarge={true}
                      meta={meta}
                      site={site}
                      isLoading={true}
                      isError={isError}
                      includeSearch={false}
                      opportunities={[
                        loadingData,
                        loadingData,
                        loadingData,
                        loadingData,
                      ]}
                      headerLevel="h2"
                      category={<Skeleton />}
                      audience={<Skeleton />}
                      sector={<Skeleton />}
                      paymentModel={<Skeleton />}
                      capabilities={<Skeleton />}
                      country={<Skeleton />}
                      applied={<Skeleton />}
                    />
                  )}
                </>
              )}

              {(dashboard || dashboardCampaignSummary) && (
                <section className="bg-light container-fluid pt-5">
                  <div className="row contained-xl justify-content-center">
                    <div className="col-lg-10">
                      <div
                      // className="opacity-80"
                      //  style={{ filter: "blur(3px)" }}
                      >
                        <div className="row justify-content-center small">
                          {dashboard && (
                            <>
                              {" "}
                              <div className="col-12">
                                <h2 className="h4 mb-3 font-family-display">
                                  Dashboard
                                </h2>
                              </div>
                              <div className="col-6 col-lg-3 mb-4">
                                <div className="card rounded-sm h-100">
                                  <div className="card-body">
                                    <div className="small text-uppercase">
                                      Accepted Leads
                                    </div>
                                    <div className="h4 mt-1 mb-1">
                                      {sumValue("accepted", dashboard)}
                                    </div>
                                    <div className="small">
                                      <span className="text-success">
                                        {(
                                          (sumValue("accepted", dashboard) /
                                            (sumValue("accepted", dashboard) +
                                              sumValue("rejected", dashboard) +
                                              sumValue("pending", dashboard) +
                                              sumValue(
                                                "quarantine",
                                                dashboard
                                              ))) *
                                          100
                                        ).toFixed(2)}
                                        %
                                      </span>{" "}
                                      of Leads
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-lg-3 mb-4">
                                <div className="card rounded-sm h-100">
                                  <div className="card-body">
                                    <div className="small text-uppercase">
                                      Rejected Leads
                                    </div>
                                    <div className="h4 mt-1 mb-1">
                                      {sumValue("rejected", dashboard)}
                                    </div>
                                    <div className="small">
                                      <span className="text-success">
                                        {(
                                          (sumValue("rejected", dashboard) /
                                            (sumValue("accepted", dashboard) +
                                              sumValue("rejected", dashboard) +
                                              sumValue("pending", dashboard) +
                                              sumValue(
                                                "quarantine",
                                                dashboard
                                              ))) *
                                          100
                                        ).toFixed(2)}
                                        %
                                      </span>{" "}
                                      of Leads
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-lg-3 mb-4 text-lg-right">
                                <div className="card rounded-sm h-100">
                                  <div className="card-body">
                                    <div className="small text-uppercase">
                                      Delivered Percentages
                                    </div>
                                    <div className="h4 mt-1 mb-3 pb-1">
                                      {(
                                        (sumValue("accepted", dashboard) /
                                          company.agreedLeadLimits) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </div>
                                    <div className="rounded py-1 bg-light overflow-hidden position-relative">
                                      <div
                                        className="position-absolute position-top py-2 bg-success"
                                        style={{
                                          width:
                                            (
                                              (sumValue("accepted", dashboard) /
                                                company.agreedLeadLimits) *
                                              100
                                            ).toFixed(2) + "%",
                                        }}
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 col-lg-3 mb-4">
                                <div className="card rounded-sm h-100 bg-info text-lg-right">
                                  <div className="card-body">
                                    <div className="small text-uppercase">
                                      YOUR Revenue
                                    </div>
                                    <div className="h4 mt-1 mb-3">
                                      &pound;{sumValue("revenue", dashboard)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {dashboard && (
                            <div className="col-12 col-lg-6 mb-4">
                              <div className="card h-100 rounded-sm">
                                <div className="card-body">
                                  <Line data={dashboard} />
                                </div>
                              </div>
                            </div>
                          )}

                          {dashboardCampaignSummary && (
                            <div className="col-12 col-lg-6 mb-4">
                              <div className="card rounded-sm h-100">
                                <div className="card-body">
                                  <StackedBar
                                    data={dashboardCampaignSummary}
                                    ChartTitle={`Campaigns`}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

              <div className="bg-light pt-4">
                <div className="container-fluid">
                  <div className="row justify-content-center contained-xl">
                    <div className="col-lg-10">
                      <div className="row justify-content-center">
                        {meta?.postOpportunitiesTermsAccepted && (
                          <div className="col-lg-12 align-content-center mb-4 ">
                            <div className="">
                              <div className="">
                                <h2 className="h4 font-family-display">
                                  Your Adverts
                                  <Link
                                    to="/marketplace/listings/"
                                    className="btn btn-outline-primary btn-sm px-1 py-0 ml-3"
                                  >
                                    Show all{" "}
                                    <Icon
                                      icon="chevron-right"
                                      className="mx-1"
                                    />
                                  </Link>
                                </h2>
                                <div className="row">
                                  <YourAdvertsHome
                                    user={user}
                                    meta={meta}
                                    isAuthenticated={isAuthenticated}
                                    isLoading={isLoading}
                                    accessToken={accessToken}
                                    companyId={companyId}
                                    company={company}
                                    limitAmount={3}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {/* TODO: hide applicants if haven't posted advert */}
                        {meta?.postOpportunitiesTermsAccepted && (
                          <div className="col-lg-12 align-content-center mb-4 ">
                            <div className="">
                              <div className="">
                                <h2 className="h4 font-family-display">
                                  Applicants to your Adverts
                                  <Link
                                    to="/marketplace/applicants/"
                                    className="btn btn-outline-primary btn-sm px-1 py-0 ml-3"
                                  >
                                    Show all{" "}
                                    <Icon
                                      icon="chevron-right"
                                      className="mx-1"
                                    />
                                  </Link>
                                </h2>
                                <div className="row">
                                  <YourApplicantsHome
                                    user={user}
                                    meta={meta}
                                    isAuthenticated={isAuthenticated}
                                    isLoading={isLoading}
                                    accessToken={accessToken}
                                    companyId={companyId}
                                    company={company}
                                    limitAmount={3}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {meta?.generateLeadTermsAccepted && (
                          <div className="col-lg-12 align-content-center w-100 mb-4 ">
                            <div className="">
                              <div className="">
                                <h2 className="h4 font-family-display">
                                  Your Latest Applications
                                  <Link
                                    to="/marketplace/applications/"
                                    className="btn btn-outline-primary btn-sm px-1 py-0 ml-3"
                                  >
                                    Show all{" "}
                                    <Icon
                                      icon="chevron-right"
                                      className="mx-1"
                                    />
                                  </Link>
                                </h2>
                                <div className="row">
                                  <YourApplicationsHome
                                    user={user}
                                    meta={meta}
                                    isAuthenticated={isAuthenticated}
                                    isLoading={isLoading}
                                    accessToken={accessToken}
                                    companyId={companyId}
                                    company={company}
                                    limitAmount={3}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Dashboard;
