import React from "react";
import { useLocation, Navigate } from "react-router-dom";

function UserProfileRequired({
  user,
  meta,
  company,
  isAuthenticated,
  isLoading,
}) {
  const location = useLocation();
  return (
    <>
      {!isLoading &&
        isAuthenticated &&
        (meta.generateLeadTermsAccepted ||
          meta.postOpportunitiesTermsAccepted) &&
        company?.companyId &&
        (!company?.companyAddress ||
          !company?.companyName ||
          !company?.companyContact) &&
        location.pathname !== "/account/" && (
          <Navigate
            to={{
              pathname: "/account/",
            }}
          />
        )}
    </>
  );
}
export default UserProfileRequired;
