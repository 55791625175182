import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import site from "./../_data/marketTheme.json";

import YourApplicants from "../Components/Marketplace/Applicants/YourApplicants";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

function YourApplicantsPage({ user, companyId, meta, accessToken, company }) {
  const params = useParams();
  const appId = params.appId;
  return (
    <>
      <Helmet>
        <title>
          {site.yourApplicantsName} / {site.account} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.yourApplicantsName,
            url: "./",
          },
        ]}
      />
      <YourApplicants
        user={user}
        company={company}
        companyId={companyId}
        meta={meta}
        accessToken={accessToken}
        appId={appId}
      />
    </>
  );
}
export default YourApplicantsPage;
