import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import site from "./../_data/marketTheme.json";
import YourApplications from "../Components/Marketplace/Applications/YourApplications";
import BreadCrumb from "../Components/Navigation/BreadCrumb";

function YourApplicationsPage({ user, company, companyId, meta, accessToken }) {
  const params = useParams();
  const appId = params.appId;

  return (
    <>
      <Helmet>
        <title>
          {site.yourApplicationsName} / {site.account} / {site.BrandName}
        </title>
      </Helmet>
      <BreadCrumb
        crumbs={[
          { title: `${site.Browsing}:` },
          {
            title: site.yourApplicationsName,
            url: "./",
          },
        ]}
      />
      <YourApplications
        user={user}
        company={company}
        companyId={companyId}
        meta={meta}
        accessToken={accessToken}
        appId={appId}
      />
    </>
  );
}
export default YourApplicationsPage;
