import React, { useState } from "react"; //useState
import ConfettiExplosion from "react-confetti-explosion";

function Confetti({ size = "large" }) {
  const [isExploded, setIsExploded] = useState(false);
  const colors = [
    "#3c4c9b",
    "#0E1134",
    "#6610f2",
    "#2d2c50",
    "#e83e8c",
    "#f43e70",
    "#ffa60d",
    "#ffba08",
    "#2ec4b6",
    "#006992",
    "#20a4f3",
  ];

  const props = {
    small: {
      force: 0.4,
      duration: 2200,
      particleCount: 30,
      width: 400,
      zIndex: 999999999999,
      colors: colors,
    },

    medium: {
      force: 0.6,
      duration: 2500,
      particleCount: 100,
      width: 1000,
      zIndex: 999999999999,
      colors: colors,
    },

    large: {
      force: 0.4,
      duration: 3000,
      particleCount: 200,
      width: 1000,
      zIndex: 999999999999,
      colors: colors,
    },
  };

  return (
    <>
      {!isExploded && (
        <div style={{ position: "absolute", left: "50%" }}>
          <ConfettiExplosion
            {...props[size]}
            onComplete={() => {
              setIsExploded(true);
            }}
          />
        </div>
      )}
    </>
  );
}
export default Confetti;
