import React from "react";
import site from "../../_data/marketTheme.json";
import { Link } from "react-router-dom";

function Loading({ error }) {
  return (
    <div className="bg-light">
      <header className="bg-primary py-4 vh-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-6 col-lg-8">
              <h1>{site.ErrorTitle}</h1>
              <p className="lead">{site.ErrorContent}</p>
              <div className="mt-5">
                <Link to="/" className={`btn btn-secondary`}>
                  {site.ErrorCTA}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
export default Loading;
