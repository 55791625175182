import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import LoginSignupButtons from "./../Authentication/LoginSignupButtons";
import logoWhite from "./../../img/logo-light.svg";
import logoDark from "./../../img/logo-dark.svg";
import HamburgerMenu from "react-hamburger-menu";
import site from "./../../_data/marketTheme.json";

export const NavBar = function ({
  isAuthenticated,
  alert,
  social,
  contact,
  user,
  meta,
  isLoading,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`${alert.alertEnable ? "with-alert" : ""}`}>
      <div
        className={`navbar-fixed-top ${
          open || site.NavDark ? site.NavBGDark : site.NavBGLight
        } ${alert.alertEnable ? "with-alert" : ""}`}
      >
        {alert.alertEnable && (
          <div className={`headeralert bg-${alert.alertBackground} py-3`}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <span className="headeralert-text">{alert.alertMessage}</span>
                <NavLink
                  onClick={() => setOpen(false)}
                  cover={`true`}
                  direction="right"
                  bg="#1F1A3C"
                  to={alert.alertLink}
                  className={
                    "btn-" + alert.alertButtonColor + " btn btn-sm ml-2"
                  }
                >
                  {alert.alertTitle}
                </NavLink>
              </div>
            </div>
          </div>
        )}

        <nav
          className={`navbar-expand-lg ${
            open || site.NavDark ? site.NavBGDark : site.NavBGLight
          }`}
          id="mainnavbar"
        >
          <div className="navbar  py-3 py-md-4 py-lg-3">
            <div className="navbar-brand">
              <NavLink
                onClick={() => setOpen(false)}
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                to="/"
                className="navbar-item"
              >
                <figure className="image">
                  {site.NavDark === true || open ? (
                    <img
                      src={logoWhite}
                      alt={site.BrandName}
                      className="brand py-1 py-lg-2"
                    />
                  ) : (
                    <>
                      <img
                        src={logoDark}
                        alt={site.BrandName}
                        className="brand dark py-1 py-lg-2"
                      />
                      <img
                        src={logoWhite}
                        alt={site.BrandName}
                        className="brand d-lg-none light py-1 py-lg-2"
                      />
                    </>
                  )}
                </figure>
              </NavLink>
            </div>

            <div className="navbar-toggler">
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => setOpen(!open)}
                width={24}
                height={16}
                strokeWidth={2.5}
                rotate={0}
                color={`
                ${
                  site.NavDark === true
                    ? open
                      ? "white"
                      : "white"
                    : open
                    ? "white"
                    : "black"
                }
              
            `}
                borderRadius={100}
                animationDuration={0.5}
              />
            </div>

            <div
              className={`collapse navbar-collapse ${
                site.NavDark === true ? `navbar-dark` : `navbar-light`
              }  ml-auto flex-nowrap ${open && `show`} `}
              id="phNavbar"
            >
              <ul id="menu-main-menu" className="navbar-nav ml-auto">
                {meta?.generateLeadTermsAccepted && (
                  <li className="nav-item">
                    <NavLink
                      onClick={() => setOpen(false)}
                      className="nav-link"
                      to="/marketplace/opportunities/"
                    >
                      {site.nav.opportunities}
                    </NavLink>
                  </li>
                )}

                {isAuthenticated &&
                (meta?.generateLeadTermsAccepted ||
                  meta?.postOpportunitiesTermsAccepted) ? (
                  <>
                    <li className="nav-item">
                      <NavLink
                        onClick={() => setOpen(false)}
                        className="nav-link"
                        to="/lead-certificate/"
                      >
                        {site.nav.leadCertificates}
                      </NavLink>
                    </li>
                    {meta?.generateLeadTermsAccepted && (
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setOpen(false)}
                          className="nav-link"
                          to="/due-diligence/"
                        >
                          {site.nav.dueDiligence}
                        </NavLink>
                      </li>
                    )}

                    <li>
                      <NavLink
                        onClick={() => setOpen(false)}
                        className="nav-link"
                        to="/content-approval/"
                      >
                        Content Approval
                      </NavLink>
                    </li>

                    {meta?.postOpportunitiesTermsAccepted && (
                      <li className="nav-item dropdown">
                        <a className={`dropdown-toggle nav-link`} href="#top">
                          <span className="">{site.nav.adverts}</span>
                        </a>
                        <div className="dropdown-menu p-0 pb-lg-3">
                          <NavLink
                            onClick={() => setOpen(false)}
                            className="nav-link"
                            to="/marketplace/opportunities/create/"
                          >
                            {site.nav.createAdvert}
                          </NavLink>
                          {meta?.generateLeadTermsAccepted && (
                            <NavLink
                              onClick={() => setOpen(false)}
                              className="nav-link"
                              to="/marketplace/applications/"
                            >
                              {site.nav.applications}
                            </NavLink>
                          )}
                          {meta?.postOpportunitiesTermsAccepted && (
                            <NavLink
                              onClick={() => setOpen(false)}
                              className="nav-link"
                              to="/marketplace/listings/"
                            >
                              {site.nav.youradverts}
                            </NavLink>
                          )}

                          {meta?.postOpportunitiesTermsAccepted && (
                            <NavLink
                              onClick={() => setOpen(false)}
                              className="nav-link"
                              to="/marketplace/applicants/"
                            >
                              {site.nav.applicants}
                            </NavLink>
                          )}
                        </div>
                      </li>
                    )}

                    {/* <li className="nav-item dropdown">
                      <a
                        className={`dropdown-toggle nav-link mr-lg-2`}
                        href="#top"
                      >
                        <span className="">{site.account}</span>
                      </a>
                      <div className="dropdown-menu p-0 pb-lg-3"> */}
                    <NavLink
                      onClick={() => setOpen(false)}
                      className="nav-link"
                      to="/account/"
                    >
                      {site.nav.profile}
                    </NavLink>
                    {/* </div>
                    </li> */}
                  </>
                ) : (
                  !isLoading &&
                  !isAuthenticated && (
                    <>
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setOpen(false)}
                          className="nav-link"
                          to="/lead-certificate/"
                        >
                          {site.nav.leadCertificates}
                        </NavLink>
                      </li>
                      {/* <li className="nav-item">
                        <a className="nav-link" href="/#howitworks">
                          How it Works
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/#about_buyers">
                          Lead Buyers
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/#about_sellers">
                          Lead Generators
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="/#pricing">
                          Pricing
                        </a>
                      </li> */}
                      {/* <li className="nav-item">
                        <a className="nav-link" href="/#contact">
                          Contact
                        </a>
                      </li> */}
                    </>
                  )
                )}

                <li className="ml-lg-2 nav-item">
                  <LoginSignupButtons
                    size="sm"
                    realSignup={
                      window.location.pathname === "/invite" ? true : false
                    }
                    className="nav-item ml-lg-2 mr-2 mr-lg-0 mt-4 mt-lg-0"
                    color="secondary"
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default NavBar;
