import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import verifiedBadge from "../img/VERIFIED-BADGE.svg";
import unVerifiedBadge from "../img/UNVERIFIED-BADGE.svg";
import unVerifiedMiniBadge from "../img/UNVERIFIED.svg";
import verifiedMiniBadge from "../img/VERIFIED.svg";

import { getDD } from "../Components/API/API";

import site from "./../_data/marketTheme.json";
import Icon from "../Components/General/Icon";
import Loading from "../Components/General/Loading";
import Error from "../Components/General/Error";
import NotFound from "../Pages/404";

function DueDiligenceView() {
  const [cert, setCert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const certId = params.cert;

  useEffect(() => {
    if (!cert && certId) {
      getDD(certId)
        .then((data) => {
          setIsLoading(false);
          if (data?.fields) {
            setCert(data.fields);
          } else {
            setCert(false);
          }
        })
        .catch((error) => {
          setIsError(true);
        });
    }
  }, [cert, certId]);

  function yesNo(val) {
    return val ? (
      <Icon icon="check-circle" color="success" />
    ) : (
      <Icon icon="times-circle" color="danger" />
    );
  }
  return (
    <section className="py-lg-5">
      <Helmet>
        <title>
          {site.nav.dueDiligence} / {site.BrandName}
        </title>
      </Helmet>

      {isLoading ? (
        <Loading bg="dark-primary" />
      ) : (
        <>
          {isError ? (
            <Error />
          ) : cert ? (
            <>
              {cert.Status !== "Live" && (
                <div className="my-5 text-center">
                  <div className="mb-2">
                    <h1 className="">Certificate Processing</h1>
                    <div className="">
                      <p>
                        {"This Due Diligence Certificate is "}
                        {
                          {
                            Processing: "awaiting processing by Partner Hype.",
                            "In Review": "currently in review by Partner Hype",
                            "Awaiting Confirmation":
                              "awaiting confirmation by the applicant.",
                            "Ready for Publishing":
                              "getting ready to be published. It shouldn't be long now.",
                          }[cert.Status]
                        }
                        {}
                      </p>
                      <p>
                        If you're the applicant, please contact us for more
                        information and a processing ETA.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="position-relative container h-100 py-lg-5">
                <div className="row justify-content-center align-self center  shadow mb-lg-5 no-gutter">
                  <div className="p-0 col-1 col-md-2">
                    <div
                      className={`h-100 w-100 ${
                        cert.valid ? "bg-primary" : "bg-danger"
                      }`}
                      // style={{
                      //   minHeight: "100%",
                      //   minWidth: "100%",
                      //   backgroundImage: cert.valid
                      //     ? "url(https://images.unsplash.com/photo-1626908013351-800ddd734b8a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1886&q=80)"
                      //     : "url(https://images.unsplash.com/photo-1615579583554-fea0e3aefd01?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1441&q=80)",
                      //   backgroundSize: "cover",
                      //   backgroundAttachment: "cover",
                      //   backgroundPosition: "center center",
                      // }}
                    ></div>
                  </div>
                  <div className="order-1 order-md-0 text-md-left col-11 col-md-10 my-md-2 text-left pt-0 px-5 pb-5 h-100">
                    <div className="text-right mt-4">
                      <img
                        alt="verification badge"
                        className="bg-white shadow d-none d-lg-block"
                        src={cert.valid ? verifiedBadge : unVerifiedBadge}
                        style={{
                          fill: "#26e07f",
                          position: "absolute",
                          left: "-17%",
                          padding: "10px",
                          borderRadius: "50%",
                          top: "-3.5vw",
                          maxHeight: "128px",
                          height: "7vw",
                          width: "7vw",
                          maxWidth: "128px",
                        }}
                      />
                      <img
                        alt="verification badge"
                        className="bg-white shadow d-lg-none"
                        src={cert.valid ? verifiedBadge : unVerifiedBadge}
                        style={{
                          fill: "#26e07f",
                          position: "absolute",
                          left: "-5%",
                          padding: "10px",
                          borderRadius: "50%",
                          height: "80px",
                          width: "80px",
                        }}
                      />

                      <div className="row justify-content-end">
                        <div className="col-8 col-lg-12">
                          <h1 className="h5 mt-2 mb-1">
                            Due Diligence Complicance Certification
                          </h1>
                          <div className="my-4 row justify-content-end">
                            <div className="col-6 col-lg-3 col-xl-2">
                              <img
                                src={
                                  cert.valid
                                    ? verifiedMiniBadge
                                    : unVerifiedMiniBadge
                                }
                                className="img-fluid shadow"
                                alt={cert.valid ? "Verified" : "Unverified"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {!cert.valid && (
                    <div className="my-5">
                      <div className="mb-2">
                        <h2 className="h5">
                          Are you the owner of this certificate?
                        </h2>
                        <div className="text-primary">
                          <p>
                            Unfortunately it looks like the certificate has
                            expired, please contact Partner Hype to star your
                            renewal process.
                          </p>
                        </div>
                      </div>
                    </div>
                  )} */}

                    <div className="row mb-5">
                      <div className="col-lg-6 order-lg-2 text-right mb-5 mb-lg-0">
                        <div className="small">
                          <span className="mr-2 font-weight-bold">
                            Certificate ID:
                          </span>
                          {cert.id}
                        </div>
                        <div className="small">
                          <span className="mr-2 font-weight-bold">
                            Expiry Date:
                          </span>
                          {cert.validUntil}
                        </div>
                      </div>
                      <div className="col-lg-6 mb-2 order-lg-1">
                        <h2 className="h3">Awarded To:</h2>
                        <div className="text-primary">
                          <div className="h5">
                            {cert.dueDiligenceCompany} -{" "}
                            {cert.dueDiligenceCompanyNum}
                          </div>
                          <div>{cert.dueDiligenceAddress}</div>
                          <div>
                            <a
                              href={cert.dueDiligenceCompanyURL}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {cert.dueDiligenceCompanyURL}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12">
                        <h2 className="h4">Compliance/DPO Contact</h2>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">Name:</span>
                        <span className="">
                          {cert.dueDiligenceComplianceContact}
                        </span>
                      </div>

                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          Tel. Number:
                        </span>
                        <span className="">
                          {cert.dueDiligenceContactPhone}
                        </span>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          Email address:
                        </span>{" "}
                        <span className="">
                          {cert.dueDiligenceComplianceEmail}
                        </span>
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-12">
                        <h2 className="h4">Company Details</h2>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          Date Of Incorporation:
                        </span>
                        <span className="">
                          {cert.dueDiligenceDateofIncorporation}
                        </span>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          Number of Employees:
                        </span>
                        <span className="">
                          {cert.dueDiligenceNumEmployees}
                        </span>
                      </div>
                      {cert.dueDiligenceTradingNames && (
                        <div className="col-lg-6 mb-2">
                          <span className="font-weight-bold mr-2">
                            Trading Names:
                          </span>
                          <span className="">
                            {cert.dueDiligenceTradingNames}
                          </span>
                        </div>
                      )}
                      {cert.dueDiligenceTradingAddress && (
                        <div className="col-lg-6 mb-2">
                          <span className="font-weight-bold mr-2">
                            Trading Address:
                          </span>
                          <span className="">
                            {cert.dueDiligenceTradingAddress}
                          </span>
                        </div>
                      )}
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          ICO / Data Protection Registration Number:
                        </span>
                        <span className="">{cert.dueDiligenceDPRNum}</span>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          FCA Firm Reference Number:
                        </span>
                        <span className="">{cert.dueDiligenceFCANum}</span>
                      </div>
                      <div className="col-lg-6 mb-2">
                        <span className="font-weight-bold mr-2">
                          FCA Permissions:
                        </span>
                        <div className="small">
                          {cert.dueDiligenceFCAPermissions}
                        </div>
                      </div>
                    </div>

                    <div className="mb-3">
                      <h2 className="h4">Properties</h2>
                      <div className="row">
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceURLs)}
                          </span>
                          <span className="font-weight-bold">List of URLs</span>
                          <div className="small ml-4">
                            {cert.dueDiligenceURLs}
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceLPBuild)}
                          </span>
                          <span className="font-weight-bold">
                            Custom Landing Pages:
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceLPBuild
                              ? "Builds"
                              : "Does not build"}{" "}
                            own Landing Pages for Campaigns when required.
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-5">
                      <h3 className="h4 mb-2">Checks</h3>
                      <div className="row">
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceLPTerms)}
                          </span>
                          <span className="font-weight-bold">
                            Landing Page Terms:
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceLPTerms
                              ? "Displays"
                              : "Does not display"}{" "}
                            compliant and transparent privacy policies and terms
                            to all users that sign up to your landing pages.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceAds)}
                          </span>
                          <span className="font-weight-bold">
                            Advert Transparancy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceAds ? "Willing" : "Unwilling"} to
                            share ads and any other assets/content used to drive
                            traffic to your pages with clients.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceCalls)}
                          </span>
                          <span className="font-weight-bold">Calls</span>
                          <div className="small ml-4">
                            {cert.dueDiligenceCalls ? "Does" : "Does not"}{" "}
                            record customer phone conversations.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceThirdParty)}
                          </span>
                          <span className="font-weight-bold">
                            Third Party Transparency
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceThirdParty ? "Can" : "Cannot"}{" "}
                            supply all the names (if name) of third party
                            companies that you leads from,
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceReference)}
                          </span>
                          <span className="font-weight-bold">
                            References Available
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceReference ? "Is" : "Is not"}{" "}
                            supply happy for new clients to contact their
                            current clients for a reference.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceDPP)}
                          </span>
                          <span className="font-weight-bold">
                            Data Protection Policy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceDPP ? "Has" : "Does not have"} an
                            up to date and regularly reviewed Data Protection
                            Policy.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceAFCP)}
                          </span>
                          <span className="font-weight-bold">
                            Anti-Financial Crime Policy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceAFCP ? "Has" : "Does not have"} up
                            to date and regularly reviewed Anti-Financial Crime
                            Policy.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceAMLP)}
                          </span>
                          <span className="font-weight-bold">
                            Anti-Money Laundering Policy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceAMLP ? "Does" : "Does not"} have
                            an up to date and regularly reviewed Anti-Money
                            Laundering Policy.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceTCS)}
                          </span>
                          <span className="font-weight-bold">
                            Training and Competency Scheme
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceTCS ? "Does" : "Does not"} have an
                            up to date and regularly reviewed Training and
                            Competency Scheme.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceFTAGCOP)}
                          </span>
                          <span className="font-weight-bold">FTAGCOP</span>
                          <div className="small ml-4">
                            {cert.dueDiligenceFTAGCOP ? "Does" : "Does not"}{" "}
                            havean up to date and regularly reviewed fair
                            treatment and good customer outcomes Policy.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceVCP)}
                          </span>
                          <span className="font-weight-bold">
                            Vulnerable Customer Policy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceVCP ? "Does" : "Does not"} have an
                            up to date and regularly reviewed vulnerable
                            customer policy.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceComplains)}
                          </span>
                          <span className="font-weight-bold">
                            Complaints Policy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceComplains ? "Does" : "Does not"}{" "}
                            have an up to date and regularly reviewed complaints
                            handling policy and procedure that meets the
                            requirements of DISP.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceEmployeeFitness)}
                          </span>
                          <span className="font-weight-bold">
                            Employee Fitness
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceEmployeeFitness
                              ? "Does"
                              : "Does not"}
                            assess the fitness & propriety of appropriate
                            employees.
                          </div>
                        </div>

                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceBCP)}
                          </span>
                          <span className="font-weight-bold">
                            Business Continuity Plan
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceBCP ? "Does" : "Does not"} have a
                            Business Continuity Plan (BCP).
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceFCAReg)}
                          </span>
                          <span className="font-weight-bold">FCA Register</span>
                          <div className="small ml-4">
                            All approved persons and appropriate FCA Permissions{" "}
                            {cert.dueDiligenceFCAReg ? "are" : "are not"}{" "}
                            displaying correctly on the FCA Register.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceSMCert)}
                          </span>
                          <span className="font-weight-bold">
                            Senior Managers & Certification Regime
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceSMCert ? "Are" : "Are not"} now
                            structured in accordance with the Senior Managers &
                            Certification Regime.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceDataHandling)}
                          </span>
                          <span className="font-weight-bold">
                            Data Handling
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceDataHandling
                              ? "Does"
                              : "Does not"}{" "}
                            have a data handling and information security policy
                            in place, and whether you are aligned and/or
                            certified to ISO27001 standards.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceGDPR)}
                          </span>
                          <span className="font-weight-bold">GDPR</span>
                          <div className="small ml-4">
                            Company {cert.dueDiligenceTCS ? "has not" : "has"}
                            been screened for GDPR compliance In the last 12
                            months including but not limited to assets such as landing pages and websites.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceDoubleOptIn)}
                          </span>
                          <span className="font-weight-bold">
                            Double Opt-In
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceDoubleOptIn ? "Can" : "Cannot"}{" "}
                            have work with and demonstrate a Double Opt in for
                            your leads.
                          </div>
                        </div>
                      </div>
                    </div>

                    {cert.dueDiligenceFCAInvestigations &&
                      cert.dueDiligenceFCAEnforcement &&
                      cert.dueDiligenceBankruptcy &&
                      cert.dueDiligenceCriminal &&
                      cert.dueDiligenceDebts && (
                        <div className="mb-5">
                          <h3 className="h4 mb-2">Flagged Issues</h3>
                          <div className="row">
                            {cert.dueDiligenceFCAInvestigations && (
                              <div className="col-6 mb-3">
                                <span className="">
                                  {yesNo(!cert.dueDiligenceFCAInvestigations)}
                                </span>
                                <span className="font-weight-bold">
                                  FCA Investigations
                                </span>
                                <div className="small ml-4">
                                  <strong>
                                    Has been subject of an FCA
                                    investigation/enquiry:
                                  </strong>
                                  <div className="font-italic">
                                    {cert.dueDiligenceFCAInvestigationsDetails}
                                  </div>
                                </div>
                              </div>
                            )}
                            {cert.dueDiligenceFCAEnforcement && (
                              <div className="col-6 mb-3">
                                <span className="">
                                  {yesNo(!cert.dueDiligenceFCAEnforcement)}
                                </span>
                                <span className="font-weight-bold">
                                  FCA Enforcement
                                </span>
                                <div className="small ml-4">
                                  Has been subject of an FCA enforcement action.
                                </div>
                              </div>
                            )}
                            {cert.dueDiligenceBankruptcy && (
                              <div className="col-6 mb-3">
                                <span className="">
                                  {yesNo(!cert.dueDiligenceBankruptcy)}
                                </span>
                                <span className="font-weight-bold">
                                  Bankruptcy
                                </span>
                                <div className="small ml-4">
                                  Has been presented with a petition for
                                  bankruptcy.
                                </div>
                              </div>
                            )}
                            {cert.dueDiligenceCriminal && (
                              <div className="col-6 mb-3">
                                <span className="">
                                  {yesNo(!cert.dueDiligenceCriminal)}
                                </span>
                                <span className="font-weight-bold">
                                  Criminal Charges
                                </span>
                                <div className="small ml-4">
                                  Has been subject to criminal investigation or
                                  proceedings.
                                </div>
                              </div>
                            )}
                            {cert.dueDiligenceDebts && (
                              <div className="col-6 mb-3">
                                <span className="">
                                  {yesNo(!cert.dueDiligenceDebts)}
                                </span>
                                <span className="font-weight-bold">Debts</span>
                                <div className="small ml-4">
                                  Has unsatisfied judgments, debts or
                                  outstanding awards against it.
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                    <div className="mb-5">
                      <h3 className="h4 mb-2">Modern Slavery Information</h3>
                      <div className="row">
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceModernSlaveryPolicy)}
                          </span>
                          <span className="font-weight-bold mb-2">
                            Modern Slavery Policy
                          </span>
                          <div className="small ml-4">
                            {cert.dueDiligenceModernSlaveryPolicy
                              ? "Does"
                              : "Does not"}{" "}
                            have a policy or policies in place to deal with
                            modern slavery.
                          </div>

                          <div className="mb-2 small ml-4">
                            {cert.dueDiligenceModernSlaveryPolicyUrl ? (
                              <a
                                href={cert.dueDiligenceModernSlaveryPolicyUrl}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cert.dueDiligenceModernSlaveryPolicyUrl}
                              </a>
                            ) : (
                              "Not supplied"
                            )}
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceModernSlaveryTraining)}
                          </span>
                          <span className="font-weight-bold">Training</span>
                          <div className="small ml-4">
                            {cert.dueDiligenceModernSlaveryTraining
                              ? "Does"
                              : "Does not"}{" "}
                            have an up to date and regularly reviewed Training
                            and Competency Scheme.
                          </div>
                        </div>
                        <div className="col-6 mb-3">
                          <span className="">
                            {yesNo(cert.dueDiligenceModernSlaveryDueDil)}
                          </span>
                          <span className="font-weight-bold">DueDil</span>
                          <div className="small ml-4">
                            {cert.dueDiligenceModernSlaveryDueDil
                              ? "Does"
                              : "Does not"}{" "}
                            engage in due diligence activities to identify,
                            prevent and mitigate risks specific to modern
                            slavery in its operations and supply chains.
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="mb-2">
                    <span className="font-weight-bold">ContactName</span>
                    <span className="">{cert.dueDiligenceContactName}</span>
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">
                      ContactPosition
                    </span>
                    <span className="">{cert.dueDiligenceContactPosition}</span>
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">ContactEmail</span>
                    <span className="">{cert.dueDiligenceContactEmail}</span>
                  </div>
                  <div className="mb-2">
                    <span className="font-weight-bold">ContactPhone</span>
                    <span className="">{cert.dueDiligenceContactPhone}</span>
                  </div> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </section>
  );
}
export default DueDiligenceView;
