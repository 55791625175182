import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import App from "./App";
import site from "./_data/marketTheme.json";

import "./scss/style.scss";
import "react-loading-skeleton/dist/skeleton.css";


Sentry.init({
  dsn: "https://107271456ac148e398e09d8087eac7a3@o4505017339609088.ingest.sentry.io/4505017340723200",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{site.brandName}</title>

      <meta name="generator" content={`${site.brandName} V.BETA.0.1A`} />
      <meta http-equiv="x-ua-compatible" content="ie=edge" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {/* <meta name="theme-color" content="#1f1a3c" />

      <link rel="icon" href="/favicon-32x32.png" type="image/png" />
      <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
      <link
        rel="manifest"
        href="/manifest.webmanifest"
        crossorigin="anonymous"
      />
      <link rel="apple-touch-icon" sizes="48x48" href="/icons/icon-48x48.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/icons/icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="96x96" href="/icons/icon-96x96.png" />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="/icons/icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-192x192.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="256x256"
        href="/icons/icon-256x256.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="384x384"
        href="/icons/icon-384x384.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="512x512"
        href="/icons/icon-512x512.png"
      /> */}

      <meta name="description" content={site.BrandMeta} />
      <meta property="og:description" content={site.BrandMeta} />
      <meta property="og:title" content={site.brandName} />
      <meta name="twitter:description" content={site.BrandMeta} />
      <meta name="twitter:title" content={site.brandName} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="" />

    </Helmet>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
    >
      <App />
    </Auth0Provider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
//
