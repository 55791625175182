import React from "react";
import Icon from "../General/Icon";

function Events({ events }) {
  return (
    <div className="events mt-5 container">
      <div className="row justify-content-center">
        <div className="col-11 col-lg-12 mx-4 px-4">
          <h2 className="h4">Events:</h2>
        </div>
      </div>
      {events &&
        events.map((event) => (
          <div className="row justify-content-center my-4" key={event.id}>
            <div className="col-11 col-lg-11 small">
              <div className="row border-light border-bottom pb-3">
                <div className="col-2">
                  {event.fields.Type === "page view" && <Icon icon="file" />}
                  {event.fields.Type === "click" && <Icon icon="arrow-pointer" />}
                  {event.fields.Type === "form submitted" && (
                    <Icon icon="circle-arrow-down" />
                  )}
                </div>
                <div className="col-4">
                  {/* <strong className="text-success">{event.fields.Type}</strong> */}
                  <span className="text-primary">
                    {event.fields["DateTime(UTC)"]}
                  </span>
                </div>
                <div className="col-4">
                  <a
                    href={event.fields.HttpReferer}
                    target="_blank"
                    rel="noreferrer"
                    className="text-primary text-underline"
                  >
                    <strong>{event.fields.UrlName}</strong> -{" "}
                    {event.fields.HttpReferer}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
export default Events;
