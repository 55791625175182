import React from "react";
import LoadingAuthentication from "./LoadingAuthentication";
import UnAuthentication from "./UnAuthentication";
import { Link } from "react-router-dom";

function RequireAuthentication({
  role = false,
  meta,
  children,
  isLoading,
  isAuthenticated,
}) {
  return (
    <>
      {isLoading && <LoadingAuthentication />}
      {!isLoading && isAuthenticated && meta?.company && (
        <>
          {role === false ||
          (role === "generateLead" &&
            meta?.generateLeadTermsAccepted === true) ||
          (role === "advertiser" &&
            meta?.postOpportunitiesTermsAccepted === true) ? (
            <>{children}</>
          ) : (
            <div className="py-5 my-5 text-center">
              <h1>You don't have permission</h1>
              <p className="lead">
                This area is for{" "}
                {role === "generateLead" ? "Lead Generators" : "Advertisors"}{" "}
                and your account is an{" "}
                {role !== "generateLead" ? "Lead Generator" : "Advertisor"}.
              </p>
              <p className="lead">
                Contact us at{" "}
                <a href="mailto:info@partnerhype.com?subject=Market Account Settings">
                  info@partnerhype.com
                </a>{" "}
                if you would like this changing.
              </p>
              <div className="mt-5">
                <Link to="/" className="btn btn-secondary btn-sm">
                  Back To Home
                </Link>
              </div>
            </div>
          )}
        </>
      )}
      {!isLoading && !isAuthenticated && <UnAuthentication />}
    </>
  );
}
export default RequireAuthentication;
