import React from "react";
import { useLocation, Navigate } from "react-router-dom";

function TermsRequired({ meta }) {
  const location = useLocation();

  return (
    <>
      {(meta?.company &&
        !meta.generateLeadTermsAccepted &&
        !meta.postOpportunitiesTermsAccepted &&
        location.pathname !== "/account/terms/") &&
        location.pathname !== "/privacy/" &&
        location.pathname !== "/terms/" &&
        location.pathname !== "/cookies/" &&
        location.pathname !== "/security/" &&
        (location.pathname !== "/pricing/" && (
          <Navigate
            to={{
              pathname: "/account/terms/",
            }}
          />
        ))}
    </>
  );
}
export default TermsRequired;
