import React from "react";
import site from "./../../_data/marketTheme.json";

function ContentApprovalDetail({
  Type,
  Status,
  opportunityName,
  Attachment,
  applyingCompanyName,
  Name,
  Description,
  URL,
  ContentUID,
  Response,
}) {
  return (
    <div className={`container-fluid bg-${site.tablePageBG} py-5 min-vh-100`}>
      <div className="row contained-xl">
        <div className={`applicationBlock offset-lg-1 col-lg-10 pt-4`}>
          <div className="row">
            <div className="col-lg-6">
              <h1>
                <span className="h5 mb-0 d-block">Content Approval</span>
                <span className="h3 mb-0 d-block">{opportunityName[0]}</span>

                <span className="h3 d-block mb-2">
                  {applyingCompanyName[0]} - {Type} - {Name}
                </span>
              </h1>
              <div className="mt-4">
                <h2 className="h4">Reference</h2>
                {ContentUID}
              </div>
              <div className="mt-4">
                <h2 className="h4">Description</h2>
                {Description}
              </div>
              {Response && (
                <div className="mt-4">
                  <h2 className="h4">Response</h2>
                  {Response}
                </div>
              )}
              <div className="mt-4">
                <h2 className="h4">Actions</h2>
                {Status === "Pending" && (
                  <>
                    <button className="btn btn-sm btn-success mr-2">
                      Approve
                    </button>
                    <button className="btn btn-sm btn-danger">Reject</button>
                  </>
                )}
                {Status === "Accepted" && (
                  <>
                    <button className="btn btn-sm btn-success" disabled>
                      Already Approved
                    </button>
                  </>
                )}
                {Status === "Rejected" && (
                  <>
                    <button className="btn btn-sm btn-danger" disabled>
                      Already Rejected
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-5 offset-lg-1">
              {Attachment && (
                <div className="">
                  <img
                    src={Attachment[0].thumbnails.large.url}
                    className="img-fluid w-100"
                    alt=""
                  />
                </div>
              )}
              {URL && (
                <div className="">
                  <iframe
                    title="Content Preview"
                    src={URL}
                    className="img-fluid w-100 border-0 vh-100"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContentApprovalDetail;
