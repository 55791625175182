import React from "react";

function LoadingAuthentication({ bg }) {
  return (
    <section className={bg ? `bg-${bg}` : ""}>
      <header className="py-5">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-6 col-lg-8 text-center">
              <div className="fa-3x">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  );
}
export default LoadingAuthentication;
