import React from "react";

const Contact = () => {
  return (
    <section className="bg-primary py-lg-5">
      <div className="container py-5 text-center">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <h2 className="font-family-display font-weight-normal">Want to speak to us?</h2>
            <p className="lead">
              If you're looking for more information, want to discuss enterprise
              pricing, or anything else, then please email us:
            </p>
            <p className="">
              <a
                href="mailto:info@partnerhype.com"
                className="btn btn-outline-secondary"
              >
                info@partnerhype.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
