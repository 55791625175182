import React, { useState, useEffect } from "react";
import site from "../../../_data/marketTheme.json";
import { Link } from "react-router-dom";
import Icon from "../../General/Icon";
import Line from "../../Charts/Line";

import { approveApplication, rejectApplication } from "./../../API/API";
import Moment from "react-moment";
import DueDiligenceIcon from "../../DueDiligence/DueDiligenceIcon";

import { getDashboard } from "../../API/API";
import { sumValue } from "../../Functions";
import Loading from "../../General/Loading";
import Error from "../../General/Error";

function YourApplicantsDetail({
  id,
  user,
  accessToken,
  applyingCompanyName,
  applyingCompanyContact,
  applyingCompanyAddress,
  applyingCompanyPhone,
  applyingCompanyEmail,
  applyingCompanyDD,
  applyingCompanyDDID,
  applyingCompanyDDValid,
  applicationNote,
  leadLimitFinal,
  updated,
  statusLive,
  setStatusLive,
  hideDashboard = false,
  applyingCompany,
}) {
  const [dashboard, setDashboard] = useState(hideDashboard);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (!dashboard && applyingCompany?.length) {
      getDashboard(user?.sub, accessToken, applyingCompany)
        .then((res) => {
          setDashboard(res);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [dashboard, accessToken, applyingCompany, user]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {isError ? (
            <Error />
          ) : (
            <div className="bg-light rounded-lg p-4 mt-3 mb-2">
              <div className="row">
                {!hideDashboard && dashboard && (
                  <div className="col-lg-12 mb-5">
                    <div className="row">
                      <div className="col-lg-9 ">
                        <div className="bg-white rounded-lg h-100 p-4">
                          <Line data={dashboard} height={300} />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="row">
                          <div className="col-6 col-lg-12 mb-4 text-lg-right">
                            <div className="card rounded-lg h-100">
                              <div className="card-body">
                                <div className="small text-uppercase">
                                  Accepted Leads
                                </div>
                                <div className="h4 mt-1 mb-1">
                                  {sumValue("accepted", dashboard)}
                                </div>
                                <div className="small">
                                  <span className="text-success">
                                    {(
                                      (sumValue("accepted", dashboard) /
                                        (sumValue("accepted", dashboard) +
                                          sumValue("rejected", dashboard) +
                                          sumValue("pending", dashboard) +
                                          sumValue("quarantine", dashboard))) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </span>{" "}
                                  of Leads
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-lg-12 mb-4 text-lg-right">
                            <div className="card rounded-lg h-100">
                              <div className="card-body">
                                <div className="small text-uppercase">
                                  Rejected Leads
                                </div>
                                <div className="h4 mt-1 mb-1">
                                  {sumValue("rejected", dashboard)}
                                </div>
                                <div className="small">
                                  <span className="text-success">
                                    {(
                                      (sumValue("rejected", dashboard) /
                                        (sumValue("accepted", dashboard) +
                                          sumValue("rejected", dashboard) +
                                          sumValue("pending", dashboard) +
                                          sumValue("quarantine", dashboard))) *
                                      100
                                    ).toFixed(2)}
                                    %
                                  </span>{" "}
                                  of Leads
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-lg-12 mb-4 text-lg-right">
                            <div className="card rounded-lg h-100">
                              <div className="card-body">
                                <div className="small text-uppercase">
                                  Delivered Percentages
                                </div>
                                <div className="h4 mt-1 mb-3 pb-1">
                                  {(
                                    (sumValue("accepted", dashboard) /
                                      parseInt(leadLimitFinal)) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </div>
                                <div className="rounded py-1 bg-light overflow-hidden position-relative">
                                  <div
                                    className="position-absolute position-top py-2 bg-success"
                                    style={{
                                      width:
                                        (
                                          (sumValue("accepted", dashboard) /
                                            parseInt(leadLimitFinal)) *
                                          100
                                        ).toFixed(2) + "%",
                                    }}
                                  ></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-lg-12 text-lg-right">
                            <div className="card rounded-lg h-100 bg-info text-lg-right">
                              <div className="card-body">
                                <div className="small text-uppercase">
                                  YOUR Revenue
                                </div>
                                <div className="h4 mt-1 mb-3">
                                  &pound;{sumValue("revenue", dashboard)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {applicationNote && (
                  <div className="col-lg-12 mb-5">
                    <h3 className="h6 mb-2">Note</h3>
                    <div>{applicationNote}</div>
                  </div>
                )}
                <div className="col-lg-4">
                  <h3 className="h6 mb-2">Address</h3>
                  <div>{applyingCompanyName}</div>
                  <div>{applyingCompanyContact}</div>
                  <div>{applyingCompanyAddress}</div>
                  <div>{applyingCompanyPhone}</div>
                  <div>{applyingCompanyEmail}</div>
                </div>
                <div className="col-lg-4">
                  <h3 className="h6 mb-2">Due Diligence</h3>
                  <div className="">
                    <DueDiligenceIcon cert={applyingCompanyDDID} />
                    {!applyingCompanyDD && (
                      <p className="mt-1">
                        This company has not taken the due diligence test
                      </p>
                    )}
                    {applyingCompanyDDValid &&
                      applyingCompanyDD &&
                      Array.isArray(applyingCompanyDDValid) &&
                      applyingCompanyDDValid[0] && (
                        <>
                          <p className="mt-2">
                            This company passed the due diligence test
                          </p>
                          <Link
                            className="btn btn-outline-primary btn-sm"
                            to={`/due-diligence/${applyingCompanyDD[0]}`}
                            target="_blank"
                          >
                            View <Icon icon="arrow-right" />
                          </Link>
                        </>
                      )}

                    {/* {applyingCompanyDD &&
                      !Array.isArray(applyingCompanyDDValid) &&
                      applyingCompanyDDValid[0] && (
                        <>
                          <p className="mt-2">
                            This company did not pass the due diligence test
                          </p>
                          <Link
                            className="btn btn-outline-primary btn-sm"
                            to={`/due-diligence/${applyingCompanyDD[0]}`}
                            target="_blank"
                          >
                            View <Icon icon="arrow-right" />
                          </Link>
                        </>
                      )} */}
                  </div>
                </div>
                <div className="col-lg-4">
                  {(statusLive === "Accepted" || statusLive === "Live") && (
                    <>
                      <h3 className="h6 mb-2">
                        Application Accepted
                        <Icon
                          icon="circle-check"
                          className="text-success ml-2"
                        />
                      </h3>
                      <p>
                        <Moment format="DD/MM/YYYY hh:mma">{updated}</Moment>
                      </p>
                    </>
                  )}
                  {statusLive === "Rejected" && (
                    <>
                      <h3 className="h6 mb-2">
                        Application Rejected
                        <Icon
                          icon="times-circle"
                          className="text-secondary ml-2"
                        />
                      </h3>
                      <p>
                        <Moment format="DD/MM/YYYY hh:mma">{updated}</Moment>
                      </p>
                    </>
                  )}
                  {statusLive === "Pending" && (
                    <>
                      <h3 className="h6 mb-2">{site.NextStepsTitle}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: site.NextStepsContent,
                        }}
                      />

                      <div className="row mb-2">
                        <div className="col-6 pr-1">
                          <button
                            className="btn btn-block btn-danger btn-sm small text-uppercase font-weight-bold"
                            onClick={() => {
                              rejectApplication(id, user.sub, accessToken);
                              setStatusLive("Rejected");
                            }}
                          >
                            {site.reject}
                          </button>
                        </div>
                        <div className="col-6 pl-1">
                          <button
                            className="btn btn-block btn-success btn-sm small text-uppercase font-weight-bold"
                            onClick={() => {
                              approveApplication(id, user.sub, accessToken);
                              setStatusLive("Accepted");
                            }}
                          >
                            {site.accept}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default YourApplicantsDetail;
