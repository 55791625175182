import React from "react";
import { Link } from "react-router-dom";
import Icon from "../General/Icon";

export default function ContentApprovalCard({
  CTA,
  Type,
  Status,
  opportunityName,
  Attachment,
  applyingCompanyName,
  Name,
  Description,
  Identifier,
  loading = false,
}) {
  return (
    <div className={`card rounded overflow-hidden pb-5 h-100`}>
      <div className="card-header bg-primary mb-0">
        <h5 className="mb-0 small">
          {Type && (
            <Icon
              icon={
                Type === "Email"
                  ? "envelope"
                  : Type === "Advert"
                  ? "image"
                  : Type === "Content"
                  ? "file"
                  : Type === "Landing Page"
                  ? "browser"
                  : Type === "Message"
                  ? "sms"
                  : "file"
              }
              className={`
      ${
        Status === "Pending"
          ? "text-warning"
          : Status === "Accepted"
          ? "text-success"
          : "text-danger"
      }`}
            />
          )}
          <span className="card-title ml-2 font-weight-bold">
            {opportunityName[0]}
          </span>
        </h5>
      </div>
      <div className="card-body p-3 small">
        {Attachment && (
          <div className="embed-responsive embed-responsive-21by9 mb-3">
            {loading ? (
              <>{Attachment}</>
            ) : (
              <img
                src={Attachment[0].thumbnails.large.url}
                alt=""
                className="card-img-top"
                style={{
                  height: "100%",
                  position: "absolute",
                  top: "0px",
                  objectFit: "cover",
                  objectPosition: "center center",
                }}
              />
            )}
          </div>
        )}
        <div className="font-weight-bold mb-2">
          <div>{applyingCompanyName[0]}</div>
          <div>{Type}</div>
          <div>{Name}</div>
        </div>
        <div>{Description}</div>
        <div
          style={{
            left: "1rem",
            bottom: "1.3rem",
            position: "absolute",
          }}
        >
          {Status === "Pending" && (
            <span className="bg-warning px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
              <i className="fa fa-clock"></i> Pending
            </span>
          )}
          {Status === "Accepted" && (
            <span className="d-lg-inline bg-success px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
              <i className="fa fa-check"></i> {Status}
            </span>
          )}
          {Status === "Rejected" && (
            <span className="d-lg-inline bg-danger px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
              <i className="fa fa-xmark"></i> {Status}
            </span>
          )}
        </div>

        {CTA && (
          <Link
            to={`/content-approval/${Identifier}`}
            className="btn btn-sm btn-primary"
            style={{
              right: "1rem",
              bottom: "1rem",
              position: "absolute",
            }}
          >
            {CTA}
          </Link>
        )}
      </div>
    </div>
  );
}
