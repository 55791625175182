import { ExternalApi } from "./ExternalApi";

const apiServerUrl = process.env.REACT_APP_API_URL;

export const getCompany = async (userId, token) => {
  const config = {
    url: `${apiServerUrl}/getCompany`,
    data: { userId: userId, token: token },
  };
  return call(config);
};

export const getDDList = async (userId, company, token) => {
  const config = {
    url: `${apiServerUrl}/getDDList`,
    data: { userId: userId, token: token, company: company },
  };
  return call(config);
};
export const getDD = async (certId) => {
  const config = {
    url: `${apiServerUrl}/getDD`,
    data: { certId: certId },
  };
  return call(config);
};

export const getDashboard = async (userId, token, company) => {
  const config = {
    url: `${apiServerUrl}/getDashboard`,
    data: { userId: userId, token: token, company: company },
  };
  return call(config);
};
export const getDashboardCampaignSummary = async (userId, token, company) => {
  const config = {
    url: `${apiServerUrl}/getDashboardCampaignSummary`,
    data: { userId: userId, token: token, company: company },
  };
  return call(config);
};

export const getSearchData = async () => {
  const config = {
    url: `${apiServerUrl}/getSearchData`,
  };
  return call(config);
};

export const getContentApproval = async (data) => {
  if (!data?.companyType) {
    data.companyType = "opportunityCompany";
  }
  const config = {
    url: `${apiServerUrl}/getContentApproval`,
    data: data,
  };
  return call(config);
};
export const getOpportunity = async (opportunityId, userId, token, company) => {
  const config = {
    url: `${apiServerUrl}/getOpportunity`,
    data: {
      opportunityId: opportunityId,
      userId: userId,
      token: token,
      company: company,
    },
  };
  return call(config);
};

export const getOpportunityOwner = async (
  opportunityId,
  userId,
  token,
  company
) => {
  const config = {
    url: `${apiServerUrl}/getOpportunityOwner`,
    data: {
      opportunityId: opportunityId,
      userId: userId,
      token: token,
      company: company,
    },
  };
  return call(config);
};

export const updateProfile = async (profile, userId, token, company) => {
  const config = {
    url: `${apiServerUrl}/updateProfile`,
    data: { ...profile, userId: userId, token: token },
  };
  return call(config);
};

export const getYourApplications = async (
  company,
  userId,
  token,
  limit = false,
  status = false,
  opportunityId = false
) => {
  const config = {
    url: `${apiServerUrl}/getYourApplications`,
    data: {
      company: company,
      limit: limit,
      userId: userId,
      token: token,
      status: status,
      opportunityId: opportunityId,
    },
  };
  return call(config);
};
export const getApplicationsToOpportunities = async (
  company,
  userId,
  token,
  limit = false,
  status = false,
  opportunityId = false
) => {
  const config = {
    url: `${apiServerUrl}/getApplicationsToOpportunities`,
    data: {
      company: company,
      limit: limit,
      userId: userId,
      token: token,
      status: status,
      opportunityId: opportunityId,
    },
  };
  return call(config);
};
export const getYourOpportunities = async (company, userId, token, limit) => {
  const config = {
    url: `${apiServerUrl}/getYourOpportunities`,
    data: {
      company: company,
      limit: limit ? limit : "",
      userId: userId,
      token: token,
    },
  };
  return call(config);
};
export const removeOpportunity = async (
  opportunityId,
  userId,
  token,
  company
) => {
  const config = {
    url: `${apiServerUrl}/removeAdvert`,
    data: { id: opportunityId, userId: userId, token: token },
  };
  return call(config);
};

export const approveApplication = async (
  applicationId,
  userId,
  token,
  company
) => {
  const config = {
    url: `${apiServerUrl}/approveApplication`,
    data: { id: applicationId, userId: userId, token: token },
  };
  return call(config);
};

export const applyOpportunity = async (
  opportunity,
  applicationNote,
  userId,
  token,
  company
) => {
  const config = {
    url: `${apiServerUrl}/applyOpportunity`,
    data: {
      opportunity: opportunity,
      applyingCompany: company,
      applicationNote: applicationNote,
      userId: userId,
      token: token,
    },
  };
  return call(config);
};

export const rejectApplication = async (applicationId, userId, token) => {
  const config = {
    url: `${apiServerUrl}/rejectApplication`,
    data: { id: applicationId, userId: userId, token: token },
  };
  return call(config);
};

export const createOpportunity = async (data, userId, company, token) => {
  const config = {
    url: `${apiServerUrl}/createOpportunity`,
    data: { ...data, userId: userId, company: company, token: token },
  };
  return call(config);
};

export const createOpportunityInvited = async (data) => {
  const config = {
    url: `${apiServerUrl}/createOpportunityInvited`,
    data: { ...data },
  };
  return call(config);
};

export const applyDD = async (data, userId, company, token) => {
  const config = {
    url: `${apiServerUrl}/createDD`,
    data: { ...data, userId: userId, company: company, token: token },
  };
  return call(config);
};

export const doAccountDeletion = async (email, company) => {
  const config = {
    url: `${apiServerUrl}/doAccountDeletion`,
    data: { email: email, company: company },
  };
  return call(config);
};

export const getOpportunities = async (
  userId,
  token,
  company,
  filters,
  limit
) => {
  const config = {
    url: `${apiServerUrl}/getOpportunities`,
    data: {
      userId: userId,
      token: token,
      filters: filters,
      company: company,
      limit: limit,
    },
  };
  try {
    return await ExternalApi({ config })
      .then((res) => res.data?.records)
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

export const getAPI = async (instance, id) => {
  const config = {
    url: `${apiServerUrl}/getAPI`,
    data: { instance: instance, id: id },
  };
  return call(config);
};

const call = async (config) => {
  try {
    return await ExternalApi({ config })
      .then((res) => res.data)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};
