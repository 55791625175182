import React from "react";
import site from "../../_data/marketTheme.json";
import { Link } from "react-router-dom";
import DueDiligenceIcon from "../DueDiligence/DueDiligenceIcon";

function TableData({
  hideHeaders,
  headers,
  content,
  setViewInfo,
  viewInfo,
  viewing,
  data,
  bgAlt,
  accent,
  homeAlt,
}) {
  bgAlt = !bgAlt ? site.tablePageALT2BG : bgAlt;
  accent = !accent ? site.tablePageAccent : accent;

  return (
    <>
      <div className={`container-fluid ${homeAlt ? "p-0" : ""}`}>
        <div className="row justify-content-center contained-xl small mb-2 d-none d-lg-block ">
          <div className="col-12 p-0">
            {!hideHeaders && (
              <div className={`bg-${accent} mb-2 p-4 px-lg-3 py-lg-2`}>
                <div className="row text-uppercase font-weight-bold">
                  {headers.map((h, i) => (
                    <div
                      key={`header-` + i}
                      className={`col-${h.mobileSize} col-lg-${h.desktopSize} ${
                        h.textAlign ? " text-" + h.textAlign : ""
                      } align-self-center font-weight-bold`}
                    >
                      {h.title}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className={`row contained-xl justify-content-center align-content-center small `}
      >
        {data.map((d) => (
          <div
            className={`col-12 ${viewInfo.id === d.id ? "activeInfo" : ""}`}
            key={d.id}
          >
            <div className={`bg-white mb-3 px-4 py-4 px-lg-3 py-lg-2`}>
              <div className="row">
                {content.map((c, ci) => (
                  <React.Fragment key={`content-${ci}`}>
                    {!c.final && (
                      <div
                        className={`col-6 mb-3 mb-lg-0 col-lg-${
                          c.desktopSize
                        } ${c.className ? c.className : ""}  ${
                          c.textAlign ? ` text-lg-${c.textAlign} ` : ``
                        } align-self-center`}
                      >
                        {c.type !== "duediligence" &&
                          c.type !== "status" &&
                          headers[ci]?.title && (
                            <span className="d-lg-none text-uppercase mr-1 font-weight-bold">
                              {headers[ci].title}:{" "}
                            </span>
                          )}
                        {c.type === "image" && d.fields[c.field] && (
                          <span className="rounded">
                            <img
                              src={d.fields[c.field][0].url}
                              alt=""
                              className="img-fluid px-3 py-1 rounded-lg bg-white"
                            />
                          </span>
                        )}

                        {c.type === "status" && (
                          <span
                            className={`${c.innerClassName} my-1 my-lg-0 d-block`}
                          >
                            <>
                              {d.fields[c.field] === "Pending" && (
                                <span className="bg-warning px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                                  <i className="fa fa-clock"></i> Pending
                                </span>
                              )}
                              {(d.fields[c.field] === "Accepted" ||
                                d.fields[c.field] === "Live") && (
                                <span className="d-lg-inline bg-success px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                                  <i className="fa fa-check"></i>{" "}
                                  {d.fields[c.field]}
                                </span>
                              )}
                              {(d.fields[c.field] === "Rejected" ||
                                d.fields[c.field] === "Removed") && (
                                <span className="d-lg-inline bg-danger px-2 py-1 mx-auto rounded shadow text-uppercase small font-weight-bold">
                                  <i className="fa fa-xmark"></i>{" "}
                                  {d.fields[c.field]}
                                </span>
                              )}
                            </>
                          </span>
                        )}

                        {c.type === "length" &&
                          (Array.isArray(d.fields[c.field]) ? (
                            <span className={c.innerClassName}>
                              {d.fields[c.field].length}
                            </span>
                          ) : (
                            <span className={c.innerClassName}>0</span>
                          ))}

                        {c.type === "duediligence" && (
                          <>
                            {d.fields[c.field] ? (
                              <Link
                                to={`/due-diligence/${d.fields[c.field][0]}`}
                              >
                                <DueDiligenceIcon
                                  cert={d.fields.applyingCompanyDDValid}
                                  className="img-fluid my-1 my-lg-0"
                                />
                              </Link>
                            ) : (
                              <DueDiligenceIcon
                                state={d.fields.applyingCompanyDDValid}
                                className="img-fluid my-1 my-lg-0"
                              />
                            )}
                          </>
                        )}

                        {!c.type && (
                          <span className={c.innerClassName}>
                            {d.fields[c.field]}
                          </span>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                ))}

                {content[content.length - 1].link && (
                  <div className="col-12 col-lg-1 px-lg-2 mt-2 mt-lg-0 text-lg-right align-self-center">
                    <Link
                      className={`btn btn-${site.tablePageButton} btn-sm py-0 mt-lg-1 px-1 text-nowrap btn-block`}
                      to={`${content[content.length - 1].link}${d.id}`}
                    >
                      View
                      <i className="ml-2 fa fa-chevron-right"></i>
                    </Link>
                  </div>
                )}

                {content[content.length - 1].view && (
                  <div className="col-12 pt-2 pt-lg-0 col-lg-2 px-lg-2 text-lg-center align-self-center">
                    <button
                      className={`btn d-block d-lg-inline-block ${
                        viewInfo.id === d.id
                          ? `btn-white`
                          : `btn-${site.tablePageButton}`
                      } btn-sm py-0 mt-lg-1 px-1 text-nowrap`}
                      onClick={function (e) {
                        setViewInfo(viewInfo.id !== d.id ? d : { id: false });
                      }}
                    >
                      Details{" "}
                      {viewInfo.id !== d.id ? (
                        <i className="ml-2 fa fa-chevron-down"></i>
                      ) : (
                        <i className="ml-2 fa fa-chevron-up"></i>
                      )}
                    </button>
                  </div>
                )}

                {viewInfo.id === d.id && (
                  <div className="col-12 mt-3">
                    <div className=" mb-2 small inner">
                      <div className="row justify-content-end">
                        <div className="col-lg-6">
                          <div className="bg-light p-4">{viewing}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
export default TableData;
